import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[Onlyletters]'
})
export class OnlylettersDirective {

  constructor(public elementRef:ElementRef) {
    this.validate(elementRef);
  }

  validate(elementRef: ElementRef){
    elementRef.nativeElement.onkeypress = (evt: KeyboardEvent) => {
      let tecla = evt.key
      let letras = '0123456789';
      let especiales = [131];
      let tecla_especial = false;
      for (var i in especiales) { if (evt.key.charCodeAt(0) == especiales[i]) { tecla_especial = true; break; } }
      if (letras.indexOf(tecla) == -1 && !tecla_especial) { evt.preventDefault(); }
    };
  }

}
