

import { ProductModel } from '@models/product.model';
import { Action, createReducer, on } from '@ngrx/store';
import { productsActions } from '@root/core/store/actions';

export interface State {
    product: ProductModel;
    productsAll: ProductModel[];
    error: string | null;
    loading: boolean;
    message: string | null;
}

export const initialState: State = {
    product: null,
    productsAll:[],
    error: null,
    loading: true,
    message: null
};

const productsReducer = createReducer(
  initialState,
  on(productsActions.load, state => ({
    ...state,
    loading: true,
    error: null,
    message: null
  })),
  on(productsActions.searchProduct, (state) => ({
    ...updateFlags(state),
  })),
// on(productsActions.searchVendors, (state) => ({ ...state, error: null, loading: true })),
  on(productsActions.searchProductSuccess, (state, {product}) => ({
    ...state,
    loading: false,
    error: null,
    message: null,
    product
  })),
  on(productsActions.searchAllProducts, (state) => ({
    ...state,
    loading: true,
    error: null,
    message: null
  })),
  on(productsActions.searchAllProductsSuccess, (state, {productsAll}) => ({
    ...state,
    loading: false,
    error: null,
    message: null,
    productsAll
  })),
  on(productsActions.searchQueryProducts, (state) => ({
    ...state,
    loading: true,
    error: null,
    message: null
  })),
  on(productsActions.searchQueryProductsSuccess, (state, {productsAll}) => ({
    ...state,
    loading: false,
    error: null,
    message: null,
    productsAll
  })),

  on(productsActions.selectProduct, (state, { product }) => {
    return {...state, product};
  }),
  on(productsActions.productError, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  on(productsActions.searchOffersProducts, (state) => ({

        ...state,
        productsAll: [],
        loading: true,
  })),
  on(productsActions.searchOffersProductsSuccess, (state, {productsAll}) => ({

        ...state,
        productsAll,
        error: null,
        loading: false,
        message: null,

  })),
);

const updateFlags = (state: State): State => {
    return {
        ...state,
        error: null,
        loading: false,
        message: null,
    };
};

export function reducer(state: State | undefined, action: Action) {
  return productsReducer(state, action);
}

export const getProductError = (state: State) => state.error;
export const getProductLoading = (state: State) => state.loading;
export const getProductMessage = (state: State) => state.message;

export const getProductCurrent = (state: State) => state.product;
export const getProductsAll= (state: State) => state.productsAll;
