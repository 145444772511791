import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public title = 'Giovanye';
  public scrolling: boolean = false;
  public boolEdit: boolean = true;
  public imgSelected: string[] = [
    'https://api.giovanye.co/storage/products/April2022/O4Q63CfjKA9pVwTNKOzy.JPG',
    'https://api.giovanye.co/storage/products/April2022/O4Q63CfjKA9pVwTNKOzy.JPG',
    'https://api.giovanye.co/storage/products/April2022/O4Q63CfjKA9pVwTNKOzy.JPG',
    'https://api.giovanye.co/storage/products/April2022/O4Q63CfjKA9pVwTNKOzy.JPG',
    'https://api.giovanye.co/storage/products/April2022/O4Q63CfjKA9pVwTNKOzy.JPG',
    'https://api.giovanye.co/storage/products/April2022/O4Q63CfjKA9pVwTNKOzy.JPG',
    'https://api.giovanye.co/storage/products/April2022/O4Q63CfjKA9pVwTNKOzy.JPG',
    'https://api.giovanye.co/storage/products/April2022/O4Q63CfjKA9pVwTNKOzy.JPG',
    'https://api.giovanye.co/storage/products/April2022/O4Q63CfjKA9pVwTNKOzy.JPG',
    'https://api.giovanye.co/storage/products/April2022/O4Q63CfjKA9pVwTNKOzy.JPG',
  ]
  constructor(public translate: TranslateService) {
    translate.setDefaultLang('es');
  }
  ngOnInit(): void {
    this.onScroll;
  }
  public onScroll($event: any): void {
    this.scrolling = false;
    if ($event.srcElement.children[0].scrollTop !== 0) {
      this.scrolling = true;
    };
    if ($event.srcElement.children[0].scrollTop == 0) {
      this.scrolling = false;
    }
  }

  listener(evento: Event) {

  }
}
