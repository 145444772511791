import { Component, OnInit } from '@angular/core';
import { Title } from 'src/app/core/interfaces/title';

@Component({
  selector: 'app-assigned-consultant',
  templateUrl: './assigned-consultant.component.html',
  styleUrls: ['./assigned-consultant.component.scss']
})
export class AssignedConsultantComponent implements OnInit {
  title: Title=  {
    icon: 'assets/img/user/user-menu-consultant-img-icon.png',
    name: 'Consultor asignado'
  };

  constructor() { }

  ngOnInit(): void {
  }

}
