import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { VendorModel } from "@models/vendor.model";

@Injectable({
    providedIn: "root",
})
export class VendorService {
    urlApi: string = environment.API_URL;
    storage: string = environment.STORAGE;
    constructor(private http: HttpClient) {}
    public getTopvendors(): Observable<any> {
        return this.http.get<any>(`${this.urlApi}vendors/get-top-vendors`).pipe(
            map((resp) => {
                let ArrayNew = [];
                resp.forEach((element) => {
                    ArrayNew.push({
                        id: element.vendor_id,
                        name: element.vendor_name,
                        color: element.color,
                        image: this.storage + element.vendor_image,
                    });
                });
                return ArrayNew;
            })
        );
    }
    public getAllVendors(): Observable<any> {
        return this.http.get<any>(`${this.urlApi}vendors/active`).pipe(
            map((resp) => {
                let ArrayNew = [];
                resp.data.forEach((element) => {
                    ArrayNew.push({
                        id: element.id,
                        name: element.name,
                        image: element.image,
                        color: element.color,
                        type: element.type_id,
                    });
                });
                return ArrayNew;
            })
        );
    }
    public getAllVendor(): Observable<VendorModel[]> {
        return this.http.get<VendorModel[]>(`${this.urlApi}vendors/active`).pipe(
            map((resp: any) => {
                let ArrayNew: VendorModel[] = resp.data;
                return ArrayNew;
            })
        );
    }
    public getAllActiveVendor(): Observable<any> {
        return this.http.get<any>(`${this.urlApi}vendors/active`);
    }
    public getVendorsByTypes(id: number): Observable<any> {
        return this.http
            .get<any>(`${this.urlApi}vendors/${id}/getVendorByTypes`)
            .pipe(
                map((resp) => {
                    let ArrayNew = [];
                    resp.forEach((element) => {
                        ArrayNew.push({
                            id: element.id,
                            name: element.name,
                            image: this.storage + element.image,
                            color: element.color,
                            type: element.type_id,
                        });
                    });
                    return ArrayNew;
                })
            );
    }
    public sendMessageSellWithUs$(data: any): Observable<any> {
        let formdata = new FormData();
        formdata.append("full_name", data.full_name);
        formdata.append("company_name", "" + data.company_name);
        formdata.append("email", data.email);
        formdata.append("phone", data.phone );
        formdata.append("city", data.city);
        formdata.append("address", data.address);
        formdata.append("message", data.message);
        if (
            data.chamber_commerce && (
            data.chamber_commerce.type === "image/jpg" ||
            data.chamber_commerce.type === "image/png" ||
            data.chamber_commerce.type === "image/jpeg"
            )
        ) {
            formdata.append(
                "chamber_commerce",
                data.chamber_commerce,
                data.chamber_commerce.name
            );
        }
        if (
            data.representative_id && (
            data.representative_id.type === "image/jpg" ||
            data.representative_id.type === "image/png" ||
            data.representative_id.type === "image/jpeg"
            )
        ) {
            formdata.append(
                "representative_id",
                data.representative_id,
                data.representative_id.name
            );
        }
        if (
            data.rut && (
            data.rut.type === "image/jpg" ||
            data.rut.type === "image/png" ||
            data.rut.type === "image/jpeg"
            )
        ) {
            formdata.append("rut", data.rut, data.rut.name);
        }
        return this.http.post<any>(`${this.urlApi}sell-with-us`, formdata);
    }
    public sendMessageContacUS$(data: any): Observable<any> {
        return this.http.post<any>(`${this.urlApi}contact`, data);
    }
}
