import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DeliveryAddressModel } from '@models/delivery-address.model';
import { ShippingCostModel } from '@models/shipping-cost.model';

@Injectable({
    providedIn: 'root',
})
export class AddressShippingService {
    urlApi: string = environment.API_URL;
    storage: string = environment.STORAGE;
    constructor(private http: HttpClient) {}
    public getAddressShipping(userId: number): Observable<any> {
        return this.http
            .get<any>(`${this.urlApi}users/${userId}/shipping-addresses`)
            .pipe(map((resp) => resp.data));
    }

    public createAddressShipping(
        data: DeliveryAddressModel
    ): Observable<DeliveryAddressModel[]> {
        return this.http
            .post<DeliveryAddressModel[]>(
                `${this.urlApi}shipping-addresses`,
                data
            )
            .pipe(
                map((resp: any) => {
                    return resp.data;
                })
            );
    }

    public getShippingCost$(data): Observable<ShippingCostModel> {
        return this.http
            .get<ShippingCostModel>(
                `${this.urlApi}shipping/cost/${data.cart_id}/${data.address_id}`
            )
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }
    public placeOrder$(carrierId, orderId): Observable<any> {
        let carrier = '';
        switch (carrierId) {
            case 2:
                carrier = 'coordinadora';
                break;
            case 3:
                carrier = 'aldia';
                break;
            default:
                carrier = 'coordinadora';
        }
        return this.http.get<any>(
            `${this.urlApi}${carrier}/place/${orderId}/order`
        );
    }
}
