import { ICardProduct } from '@interfaces/card-products-interfaces';


export const productMocks:ICardProduct[]  = [
    {
        id:1,
        image: "../../../assets/img/giovanye/desktop/slider-18.JPG",
        nameProduct: "Pantalon",
        ref: "8399384",
        price: 74000,
        tallas:[
            "S","M","L","XL"
        ],
        favorite:true,
        new: true,
        dcto:true
    },
    {
        id:1,
        image: "../../../assets/img/giovanye/desktop/slider-16.JPG",
        nameProduct: "Pantalon",
        ref: "8399384",
        price: 74000,
        tallas:[
            "S","M","L","XL"
        ],
        favorite:false,
        new: false,
        dcto:true
    }
    ,
    {
        id:1,
        image: "../../../assets/img/giovanye/desktop/slider-13.JPG",
        nameProduct: "Pantalon",
        ref: "8399384",
        price: 74000,
        tallas:[
            "S","M","L","XL"
        ],
        favorite:false,
        new: false,
        dcto:true
    }
    ,
    {
        id:1,
        image: "../../../assets/img/giovanye/desktop/slider-14.JPG",
        nameProduct: "Pantalon",
        ref: "8399384",
        price: 74000,
        tallas:[
            "S","M","L","XL"
        ],
        favorite:false,
        new: false,
        dcto:true
    },
    {
        id:1,
        image: "../../../assets/img/giovanye/desktop/slider-06.JPG",
        nameProduct: "Pantalon",
        ref: "8399384",
        price: 74000,
        tallas:[
            "S","M","L","XL"
        ],
        favorite:true,
        new: false,
        dcto:true
    },

]