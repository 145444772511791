import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import Swal from "sweetalert2";

import * as fromAppRoot from "@reducers/index";
import { advertisingActions } from "@root/core/store/actions";
import { AdvertisingModel } from "@models/advertising-model";

@Injectable({
    providedIn: "root",
})
export class AdvertisingFacadeService {
    constructor(private store: Store<fromAppRoot.State>) {}

    getAdvertising$(): Observable<AdvertisingModel> {
        return this.store.select(fromAppRoot.getAdvertising);
    }
    getAdvertisingAll$(): Observable<AdvertisingModel[]> {
        return this.store.select(fromAppRoot.getAdvertisingAll);
    }
    getAdvertisingAllActive$(): Observable<AdvertisingModel[]> {
        return this.store.select(fromAppRoot.getAdvertisingAllActive);
    }
    searchAdvertisingAll(): void {
        this.store.dispatch(advertisingActions.searchAllAdvertisings());
    }
    searchAdvertisingAllActive(): void {
        this.store.dispatch(advertisingActions.searchAllActiveAdvertisings());
    }
    getMessage$(): Observable<string> {
        return this.store.select(fromAppRoot.getAddressShippingMessage);
    }
    getMessageError$(): Observable<string> {
        return this.store.select(fromAppRoot.getAddressShippingError);
    }
}
