import { Action, createReducer, on } from '@ngrx/store';
import { favoritesActions } from '@root/core/store/actions';

export interface State {
    products: any[];
    error: string | null;
    loading: boolean;
    message: string | null;
}

export const initialState: State = {
    products: null,
    error: null,
    loading: false,
    message: null,
};

const favoritesReducer = createReducer(
    initialState,
    on(favoritesActions.getFavorites, (state) => ({
        ...updateFlags(state),
    })),
    on(favoritesActions.getFavoritesFromDB, (state ) => ({
        ...updateFlags(state),
    })),
    on(favoritesActions.getFavoritesFromDBSuccess, (state, { products }) => ({
        ...state,
        products,
        error: null,
        loading: false,
    })),
    on(favoritesActions.setFavoritesSuccess, (state, { response }) => ({
        ...updateFlags(state),
        message: response.message
    })),
    on(favoritesActions.error, (state, { error }) => ({
        ...updateFlags(state),
        error,
    }))
);

const updateFlags = (state: State): State => {
    return {
        ...state,
        error: null,
        loading: false,
        message: null,
    };
};

export function reducer(state: State | undefined, action: Action) {
    return favoritesReducer(state, action);
}
export const getfavorites = (state: State) => state.products;
export const getError = (state: State) => state.error;
export const getLoading = (state: State) => state.loading;
export const getMessage = (state: State) => state.message;
