

<div class="products-card featured-products-wrapper page-container">
    <div>
        <div class="featured-products-header">
            <span class="font-title">  {{ carouselTitle| uppercase }}</span>
        </div>
        <div class="category-content carousel-wrapper">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let slider of sliders">

                    <mat-card class="owl-carousel-wrapper-item">
                        <mat-card-content class="owl-carousel-item">
                            <a routerLink="/articulo/{{ slider.id }}">
                            <div mat-card-avatar class="category-img-product owl-carousel-item-image-container">
                                    <img [src]="slider.image" class="img-product" [alt]="slider.productName" alt="">
                                </div>
                            </a>
                            <div class="btn-sizes">
                                <div class="category font-text">
                                    <div class="category-description ">
                                       <span class="category-description-name" >{{ slider.categories }}</span>
                                        <span>{{ slider.productName }}</span>
                                    </div>
                                    <div class="category-price">
                                        <!-- <span> {{ slider.price | moneda }}</span> -->
                                        <span [class]="slider.sale_price > 0 ? 'product-price tachado': 'product-price'">{{
                                          slider.price | moneda}}</span>
                                    </div>
                                    <div class="category-price font-text" style="height: 30px ;" >
                                      <span class="text-muted" *ngIf="slider.sale_price > 0">Oferta</span>
                                      <span class="product-price" *ngIf="slider.sale_price > 0">{{ slider.sale_price |
                                          moneda
                                          }}</span>
                                  </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
