<div class="header-wrapper" [ngClass]="{ 'menu-is-open': false ,isLoggedIn: isLoggedIn }" *ngIf="!isLoggedIn">
    <div class="header-navigation">
       <div (click)="toggleMenu()">
        <button class="menu-icon" >
            <img class="img-fluid" src="assets/img/giovanye/desktop/menu.png" alt="Giovanye logo" />

        </button>
       </div>
        <!-- <div class="vendortitle">Menú</div> -->
        <div>
            <button (click)="goToCart()" attr.aria-label="{{
            'header.cart-label'
                | translate: { totalProducts: totalProducts }
        }}" class="menu-icon-cart" *ngIf="vendor">
                <img src="/assets/img/cart-icon-white.png" alt="">
                <span class="badget" *ngIf="totalProducts >= 0">{{
                    totalProducts
                    }}</span>
            </button>
        </div>
    </div>

    <div class="header-logo-wrapper">
        <div class="header-logo">
            <a id="logo" routerLink="/">
                <!-- <img src="{{logo}}" alt="Obbro logo" /> -->
                <img class="img-fluid"  src="assets/img/giovanye/desktop/logo_blanco.png" alt="" />
            </a>
        </div>
    </div>




</div>

<!--  -->
