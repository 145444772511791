import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sizes',
  templateUrl: './sizes.component.html',
  styleUrls: ['./sizes.component.scss']
})
export class SizesComponent {
  @Input() sizesAviables: any[] = [];
  @Output() sizeSelected: EventEmitter<string> = new EventEmitter()
  onClick(sizeSelected: string) {
    this.sizeSelected.emit(sizeSelected);
  }
}
