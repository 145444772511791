import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Injectable } from "@angular/core";
import { NotifierComponent } from "src/app/shared/components/notifier/notifier.component";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  constructor(private snackBar: MatSnackBar) { }
  /**
   *
   * @param message mensaje: una cadena de texto
   * @param buttontex button: titulo del boton
   */
  showStandar(message, buttontex) {
    this.snackBar.open(message, buttontex, {
      duration: 5000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });
  }
  /**
   *
   * @param message mensaje: una cadena de texto
   * @param buttontex button: titulo del mensaje
   * @param messageType  cadena de texto con las opciones error, success, warn
   */
  showCustom(
    message: string,
    buttontex: string = null,
    messageType: "error" | "success" | "warn" | "giovanye"
  ) {
    let timeDuration;
    switch (messageType) {
      case "error":
        timeDuration = 10000;
        break;
      case "success":
        timeDuration = 4000;
        break;
      case "warn":
        timeDuration = 10000;
        break;
      case "giovanye":
        timeDuration = 10000;
        break;
      default:
        timeDuration = 2000;
    }
    this.snackBar.openFromComponent(NotifierComponent, {
      data: {
        message,
        buttontex,
        type: messageType,
      },
      duration: timeDuration,
      horizontalPosition: "center",
      verticalPosition: "bottom",
      panelClass: messageType
    });
  }
}
