import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sin-resultados',
  templateUrl: './sin-resultados.component.html',
  styleUrls: ['./sin-resultados.component.scss']
})
export class SinResultadosComponent implements OnInit {
@Input() title: string= 'SIN RESULTADOS';
@Input() detail: string ="No se encontraron Productos";
  constructor() { }

  ngOnInit(): void {
  }

}
