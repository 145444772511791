import { Component, OnInit } from '@angular/core';
import { footerMenuListData } from '@root/test-helpers/footer-list';
import { FooterMenuList } from '../footer/footer-list.interface';

@Component({
  selector: 'app-footer-social-networks',
  templateUrl: './footer-social-networks.component.html',
  styleUrls: ['./footer-social-networks.component.scss']
})
export class FooterSocialNetworksComponent implements OnInit {
    footerList: FooterMenuList[] = footerMenuListData;
  constructor() { }

  ngOnInit(): void {
  }

}
