import { Title } from 'src/app/core/interfaces/title';

export const typeListData: Title[] = [

    {
        id: '1',
        icon: 'assets/img/giovanye/desktop/category01.JPG',
        name: 'Hombre',
        link: '/lista-articulos/180'
    },
    {
        id: '2',
        icon: 'assets/img/giovanye/desktop/category05.JPG',
        name: 'Accesorios',
        link: '/lista-articulos/198'
    },
    {
        id: '3',
        icon: 'assets/img/giovanye/desktop/category03.JPG',
        name: 'Mujer',
        link: '/lista-articulos/181'
    },




















    // {
    //     id: '1',
    //     icon: 'assets/img/categories/category-1.png',
    //     name: 'Construcción y Remodelación'
    // },
    // {
    //     id: '2',
    //     icon: 'assets/img/categories/category-2.png',
    //     name: 'Eléctricos'
    // },
    // {
    //     id: '4',
    //     icon: 'assets/img/categories/category-3.png',
    //     name: 'Seguridad Industrial'
    // },
    // {
    //     id: '3',
    //     icon: 'assets/img/categories/category-4.png',
    //     name: 'Ferretero'
    // }
];
