const SERVER = 'https://api.giovanye.co';
export const environment = {
  production: true,
  DOMAIN: 'https://giovanye.co',
  SERVER: SERVER,
  API_URL: SERVER + '/api/v1/',
  HOST: 'http://localhost:4200/',
  TITLE: '',
  LOGO: '',
  STORAGE: SERVER + '/storage/',
  URL_MAP: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  URL_GEOLOCATION: 'https://maps.googleapis.com/maps/api/geocode/',
  APIKEYGOOGLE: 'AIzaSyACLJCi5yyANw6JWy1p812Og3ekttYXnAc',
  CLIENT_ID_GOOGLE:
    '1042580310681-dqo3c56bjj3jq2q5mkaffiasmr7g780v.apps.googleusercontent.com',

  SECRET_ID_GOOGLE: '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',
  CLIENT_ID_FACEBOOK: '1160662724867991',

  EPAYCO_KEY: 'e790a1799a829468c8608daf2997e0a0',

  WOMPI_PUBLIC_KEY: 'pub_prod_V32spmbx6dkhjyVRRWw2d36VnzWTRkXA',
  WOMPI_INTEGRITY_SIGNATURE: 'prod_integrity_Nmii7fldE4gLkgdHJZYcQAtJVgeBs9Vk',
  PAYMENT_GATEWAY: 'mixto',

  SISTECREDITO_SUBSCRIPTION_KEY_STAGING: '42fee88913d34e3b8c2c5ae45847bc32',
  SISTECREDITO_SUBSCRIPTION_KEY_PRODUCCION: '42fee88913d34e3b8c2c5ae45847bc32',
  SISTECREDITO_URL: 'https://api.credinet.co/paymentpublic/StartCredit',
  SISTECREDITO_VENDORID: '5d2e9c204abed5000118f934',
  SISTECREDITO_STOREID: '627c1fd731ab02000128dc7f',
  SISTECREDITO_SCORIGEN: 'Production',
  SISTECREDITO_PAY: true,
  SISTECREDITO_RESPONSE_URL: 'https://api.giovanye.co/api/v1/payment-info',
  URL_API_SERVER: ""
};

