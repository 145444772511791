import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Title } from 'src/app/core/interfaces/title';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
  title: Title = {
    name: 'Medios de pago'
  };

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

}
