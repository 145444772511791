import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAppRoot from '@reducers/index';
import { productsActions, vendorActions } from '@root/core/store/actions';
import { VendorModel } from '@models/vendor.model';
import { ProductModel } from '@models/product.model';

@Injectable({
    providedIn: 'root',
})
export class ProductFacadeService {
    constructor(private store: Store<fromAppRoot.State>) {}

    getCurrentProduct$(): Observable<ProductModel> {
        return this.store.select(fromAppRoot.getProductCurrent);
    }
    getAllProduct$(): Observable<ProductModel[]> {
        return this.store.select(fromAppRoot.getProductsAll);
    }
    searchAllProduct() {
        this.store.dispatch(productsActions.searchAllProducts());
    }

    selectProduct(product: ProductModel) {
        this.store.dispatch(productsActions.selectProduct({ product }));
    }

    searchProductByQuery(query: string) {
        this.store.dispatch(productsActions.searchQueryProducts({ query }));
    }

    searchOffersProduct() {
        this.store.dispatch(productsActions.searchOffersProducts());
    }

}
