<app-loading id="loadingMain">Loading...</app-loading>
<div class="container-wrapper">
  <whatsapp-float></whatsapp-float>
  <app-header class=""></app-header>
  <div class="">
    <app-nav [dark]="scrolling"></app-nav>
  </div>

  <router-outlet (window:scroll)="onScroll($event)"></router-outlet>

  <div class="footer-container">
    <app-footer [show]="false"></app-footer>
  </div>
  <div class="copyright-cowlor">
    <div class="copy-right-container page-container"></div>
  </div>
</div>