<!-- <mat-card class="example-card">
    <mat-card-header>
        <mat-card-title>Restablecimiento de Contraseña</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="formulario" >
            <div class="form-row">
              <div class="col-8">
                <input type="email" class="form-control" placeholder="Email" formControlName="email" >
              </div>
              <div class="col-4">
                <input type="text" class="form-control" placeholder="código" formControlName="token">
              </div>
            </div>
            <div class="form-row mt-2">
                <div class="col-6">
                  <input type="password" class="form-control" placeholder="Contraseña nueva" formControlName="password">
                </div>
                <div class="col-6">
                  <input type="password" class="form-control" placeholder="Confirmar Contraseña">
                </div>
            </div>
          </form>
    </mat-card-content>
    <span>Nota:El código Se le envió a su email, no cierre esta ventana.</span>
    <mat-card-actions>
        <button class="btn btn-danger btn-block" (click)="guardar()" [disabled]="formulario.invalid" >Cambiar</button>
    </mat-card-actions>
</mat-card> -->
<!-- <button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
  {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
</button> -->
<mat-card class="example-card">
  <mat-horizontal-stepper  [linear]="isLinear" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Correo Electrónico</ng-template>
        <input class="form-control" placeholder="Ingrese su email" formControlName="firstCtrl" required>
        <div>
          <button  [disabled]="firstFormGroup.invalid" (click)="resetPass()" class="btn btn-warning float-right mt-4"  matStepperNext>Siguiente</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" label="Nueva Contraseña">
      <form [formGroup]="secondFormGroup">
        <mat-card *ngIf="emailEnviado">
          <mat-card-content>
            <form [formGroup]="formulario">
              <div class="form-row">
                <div class="col-8">
                  <input type="email" class="form-control" placeholder="Email" formControlName="email">
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" placeholder="código" formControlName="token">
                </div>
              </div>
              <div class="form-row mt-2">
                <div class="col-6">
                  <input type="password" class="form-control" placeholder="Contraseña nueva" formControlName="password">
                </div>
                <div class="col-6">
                  <input type="password" class="form-control" placeholder="Confirmar Contraseña">
                </div>
              </div>
            </form>
          </mat-card-content>
          <span>Nota:El código se le envió a su email, por favor no cierre esta ventana.</span>
          <mat-card-actions>
            <button class="btn btn-danger btn-block" (click)="guardar()"
              [disabled]="formulario.invalid" matStepperNext>Cambiar</button>
          </mat-card-actions>
        </mat-card>

        <h4 *ngIf="!emailEnviado">{{ mensajeError }}</h4>

        <div>
          <button class="btn btn-block btn-danger">atras</button>
          <!-- <button mat-button matStepperNext>Siguiente</button> -->
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Final</ng-template>
      <h3 class="text-center">{{ mensajeFinal }}</h3>
      <div>
        <button class="btn btn-danger" matStepperPrevious>Atras</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</mat-card>