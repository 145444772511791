import { UtilsService } from "./../../../utilities/utils.service";
import { Component, Injectable, OnInit } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { LegacyProgressSpinnerMode as ProgressSpinnerMode } from "@angular/material/legacy-progress-spinner";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
  standalone: true,
  imports: [CommonModule,MatProgressSpinnerModule]
})
@Injectable()
export class LoadingComponent {
  public color: ThemePalette = 'primary';
  public mode: ProgressSpinnerMode = 'indeterminate';
  public value = 50;
  public ver = false;
  constructor(private utilsService: UtilsService) {
    this.utilsService.loading$.subscribe((DATA) => (this.ver = DATA));
  }

}
