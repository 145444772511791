import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CartFacadeService } from '@root/pages/cart/cart-facade.service';

@Component({
    selector: 'app-product-add-cart',
    templateUrl: './product-add-cart.component.html',
    styleUrls: ['./product-add-cart.component.scss'],
})
export class ProductAddCartComponent implements OnInit {
    @Input() item: any = {
        img: '',
        name: '',
        price: 0,
        ref: '',
    };

    constructor(
        public dialogRef: MatDialogRef<ProductAddCartComponent>,
        private cartfacade: CartFacadeService
    ) {}

    ngOnInit(): void {
        this.cartfacade.getCart$().subscribe((i) => {
            const item = i[i.length - 1];
            if (item) {
                this.item = {
                    img: item.image,
                    name: item.name,
                    price: item.regular_price,
                    ref: item.sku,
                    qty: item.qty,
                };
            }
        });
    }

    onClose(): void {
        this.dialogRef.close();
    }
}
