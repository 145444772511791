import { MessageService } from './../../../core/services/message.service';
import Swal from 'sweetalert2';
import { UserFacadeService } from './../../../core/facades/auth/user-facade.service';
import { Subscription } from 'rxjs';
import { FavoriteService } from './../../../core/services/favorite.service';
import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'src/app/core/interfaces/featuredproducts';
import { FavoriteFacadeService } from '../favorites/store/favorites-facade.service';
import { productMocks } from '@root/core/mocks/card-products-mocks';
import { ICardProduct } from '@interfaces/card-products-interfaces';
import { DataService } from '../../../core/services/Data/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-products-card',
  templateUrl: './products-card.component.html',
  styleUrls: ['./products-card.component.scss'],
})
export class ProductsCardComponent implements OnInit {
  @Input() product: Product;
  public sizes = ['S', 'M', 'L', 'XL'];
  public subs: Subscription = new Subscription();
  public list = [];
  private user;
  constructor(
    private favoriteFacade: FavoriteFacadeService,
    private userFacade: UserFacadeService,
    private messageService: MessageService,
    private dataService: DataService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscriptions();
  }
  private subscriptions() {
    let sub = this.userFacade.getCurrentUser$().subscribe((U) => {
      if (U) {
        this.user = U;
      }
    });
    this.subs.add(sub);
    let sub2 = this.favoriteFacade
      .getFavoriteMessage$()
      .subscribe((msg) => {
        if (msg && msg !== '') {
          this.messageService.showCustom(msg, null, 'success');
        }
      });
    this.subs.add(sub2);
  }

  setFavorite(productsId) {
    if (this.user) {
      this.favoriteFacade.setFavorite(productsId);
      this.product.isFavorite = !this.product.isFavorite;
    } else {
      Swal.fire({
        title: 'FAVORITOS',
        text: 'Debe estar logueado',
        icon: 'warning',
      });
    }
  }

  public goToArticulo() {
    this.dataService.setLastRoute(this.router.url)
  }
}
