import { createAction, props } from '@ngrx/store';
import { DeliveryAddressModel } from '@models/delivery-address.model';



export const getAddressShipping = createAction(
    '[AddressShipping] Get  Address Shipping',
    props<{ idUser: number }>()
);
export const getAddressShippingSuccess = createAction(
    '[AddressShipping] Get Address Shipping Success',
    props<{ addresses: DeliveryAddressModel[] }>()
);
export const getAddressShippingError = createAction(
    '[AddressShipping] Get Address Shipping Error',
    props<{ error: string }>()
);
export const setAddressShipping = createAction(
    '[AddressShipping] Set Address Shipping',
    props<{ address: DeliveryAddressModel }>()
);

export const createAddressShipping = createAction(
    '[AddressShipping] Create Address Shipping',
    props<{ address: DeliveryAddressModel }>()
);
export const createAddressShippingSuccess = createAction(
    '[AddressShipping] Create Address Shipping Success',
    props<{ addressAll: DeliveryAddressModel[] }>()
);
