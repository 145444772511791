<div class="jumbotron">
    <div>
        <div>
            <img [src]="data.image" [alt]="data.ProductName" class="img-fluid">
        </div>
        <div class="jumbotron-content">
            <div class="">
                <h1 class="title font-hoardings">{{ data.ProductName }} </h1>
                <p class="d-none d-md-block description-hoarding"> {{ data.description }} </p>
                <a class="btn btn-dark btn-lg font-hoardings-btn" [routerLink]="data.url_redirect" role="button">DESCÚBRELOS AHORA</a>
            </div>
        </div>
    </div>
</div>
