import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { environment } from '@environments/environment';
import { AddProductCartModel } from '@models/addProductCart.model';
import { CartCodeModel } from '@models/cart-code.model';
import { CartModel } from '@models/cart.model';
import { CartContentModel } from '@models/cart-content.model';
import { CartConfirmShopping } from '@models/cart-confirm-shopping.model';
import { CorfirmPurchaseModel } from '@models/corfirmPurchase.model';
import { CartOrderResponseModel } from '@models/cart-order-response.model';
import { MessageService } from './message.service';
import { CartDeleteItemModel } from '@models/cart-delete-item.model';
import { CartDeleteAllItemInterface } from '@interfaces/cart-delete-all-Items-interface';
import { RequestGetCarCodeModel } from '@models/cart-request-get-cart-code-model';
import { CartUpdateItemsModel } from '@models/cart-update-items.model';
import { SaleExpress } from '../interfaces/saleExpress.interface';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    urlApi: string = environment.API_URL;
    constructor(
        private http: HttpClient,
        private messageService: MessageService
    ) {}

    public getCarCode(data: CartCodeModel): Observable<any> {
        return this.http.get<any>(
            `${this.urlApi}users/${data.userId}/${data.vendorId}/${data.cart_type}/cart-code`
        );
    }
    public getCarCodeII(
        params: RequestGetCarCodeModel
    ): Observable<CartModel[]> {
        return this.http.get<CartModel[]>(
            `${this.urlApi}users/${params.id}/${params.vendorId}/${params.cart_type}/cart-code`
        );
    }
    public getCode(params: RequestGetCarCodeModel): Promise<CartModel[]> {
        return this.http
            .get<CartModel[]>(
                `${this.urlApi}users/${params.id}/${params.vendorId}/${params.cart_type}/cart-code`
            )
            .toPromise();
    }
    public async addProductCart$(
        toBuy: AddProductCartModel
    ): Promise<CartContentModel> {
        return await this.http
            .post<CartContentModel>(this.urlApi + `cart/add-product`, toBuy)
            .pipe(
                map((resp: any) => {
                    if (resp.data) {
                        return resp.data;
                    } else {
                        this.messageService.showCustom(
                            resp.message,
                            null,
                            'error'
                        );
                        return resp.message;
                    }
                })
            )
            .toPromise();
    }
    public getCart$(cartCode: string): Observable<CartContentModel> {
        return this.http
            .get<CartContentModel>(this.urlApi + `cart/${cartCode}`)
            .pipe(
                tap((resp) => {
                    return resp;
                })
            );
    }
    public getCartUser$(userId: number): Observable<CartModel[]> {
        const options = {
            params: new HttpParams().append('user_id', userId + ''),
            body: new HttpParams().append('user_id', userId + ''),
        };
        return this.http
            .get<CartModel[]>(this.urlApi + `cart/getLastCartUser`, options)
            .pipe(
                tap((resp) => {
                    return resp;
                })
            );
    }
    public cartConfirmShopping$(
        purchase: CorfirmPurchaseModel
    ): Observable<CartOrderResponseModel> {
        return this.http
            .post<CartOrderResponseModel>(
                this.urlApi + `cart/checkout`,
                purchase
            )
            .pipe(
                tap((resp) => {
                    return resp;
                })
            );
    }
    public deleteCartItem$(item: CartDeleteItemModel): Observable<any> {
        return this.http
            .post<any>(this.urlApi + `cart/remove-product`, item)
            .pipe(
                tap((resp) => {
                    return resp;
                })
            );
    }
    public deleteCartAllItem$(
        item: CartDeleteAllItemInterface
    ): Observable<any> {
        return this.http.post<any>(this.urlApi + `cart/remove-all`, item).pipe(
            tap((resp) => {
                return resp;
            })
        );
    }
    public updateCartItem$(item: CartUpdateItemsModel): Observable<any> {
        return this.http
            .post<any>(this.urlApi + `cart/edit-product`, item)
            .pipe(
                map((resp) => {
                    return [...resp.data.items];
                })
            );
    }
    public saleExpress(sale: SaleExpress): Observable<any> {
        return this.http
            .post<any>(this.urlApi + `cart/sales/express`, sale);
    }
}
