import { Injectable } from '@angular/core';
import { Company } from '@interfaces/company';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private lastRoute$: BehaviorSubject<string> = new BehaviorSubject<string>("")
  constructor() { }
  public setLastRoute(route: string): void {
    this.lastRoute$.next(route)
  }
  public getLastRoute$(): Observable<string> {
    return this.lastRoute$.asObservable();
  }
}
