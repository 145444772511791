<div class="payment-wrapper">
    <div class="payment-content">
        <div class="payment-icon"></div>
        <div class="payment-items">
            <div class="payment-title">
                <span>Matelsoft sas</span>
            </div>
            <div class="payment-subtitle"><span>compra de prueba</span></div>
            <div class="payment-price"><span>325,000 COP</span></div>
        </div>
    </div>

    <div class="payment-alert">
        <span>Ingresa tu correo electrónico para iniciar</span>
    </div>
    <div class="payment-email">
        <img src="/assets/img/mail-icon.png" alt=""> <label for="">Email</label>
        <input type="email" class="input-payment-email" placeholder="correo@correo.com">
        <i class="fas fa-pen payment-icon"></i>
    </div>
    <app-title [title]="title"></app-title>
    <div class="payment-inputs-pays">
        <div class="payment-inputs">
            <input type="radio" name="credit" value="1">
            <input type="text" class="types-pays" name="credit-card" disabled placeholder="Tarjeta de Crédito">
        </div>
        <div class="payment-inputs">
            <input type="radio" name="credit" value="2">
            <input type="text" class="types-pays" name="pse" disabled placeholder="PSE, Cuentas de ahorro y corriente">
        </div>
        <div class="payment-inputs">
            <input type="radio" name="credit" value="3">
            <input type="text" class="types-pays" name="cash" disabled placeholder="Efectivo">
        </div>
        <div class="payment-inputs">
            <input type="radio" name="credit" value="4">
            <input type="text" class="types-pays" name="safetypay" disabled placeholder="SafetyPay">
        </div>
    </div>

    <div class="payment-logo">
        <button><i class="fas fa-lock"></i> Pago seguro con<img src="/assets/img/epayco.png" alt=""></button>
    </div>
</div>
