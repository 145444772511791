import {
    createSelector,
    createFeatureSelector,
    Action,
    ActionReducerMap,
} from '@ngrx/store';
import { InjectionToken } from '@angular/core';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromAuth from './webReducers';
import * as fromCart from '../../../pages/cart/store/cart.reducer';
import * as fromAddress from '@root/features/delivery-address/store/delivery-address.reducer';
import * as fromOrders from '@root/features/orders/store/orders-reducer';
import * as fromVendor from '@root/features/vendor/vendor-store/vendor-reducer';
import * as fromProduct from '@root/shared/components/product/store/product-reducer';
import * as fromAdvertising from '@root/shared/components/advertising/store/advertising-reducer';
import * as fromfavorites from '@root/shared/components/favorites/store/favorites.reducer';
import * as fromCategories from '@root/shared/components/categories/store/categories.reducer';
import * as fromNavCategories from '@root/shared/components/nav/store/nav.reducer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
    auth: fromAuth.State;
    cart: fromCart.State;
    address: fromAddress.State;
    orders: fromOrders.State;
    vendor: fromVendor.State;
    product: fromProduct.State;
    advertising: fromAdvertising.State;
    favorites: fromfavorites.State;
    categories: fromCategories.State;
    navCategories: fromNavCategories.State;
}

export const ROOT_REDUCERS = new InjectionToken<
    ActionReducerMap<State, Action>
>('Root reducers token', {
    factory: () => ({
        auth: fromAuth.reducer,
        cart: fromCart.CartReducer,
        address: fromAddress.AddressShippingReducer,
        orders: fromOrders.OrdersReducer,
        vendor: fromVendor.VendorReducer,
        product: fromProduct.reducer,
        advertising: fromAdvertising.reducer,
        favorites: fromfavorites.reducer,
        categories: fromCategories.reducer,
        navCategories: fromNavCategories.reducer,
    }),
});

// ================[AUTENTICACION]======================================\\
//#region AUTENTICACION
export const getAuthState = createFeatureSelector<fromAuth.State>('auth');

export const getLoggedIn = createSelector(getAuthState, fromAuth.getLoggedIn);

export const getUser = createSelector(getAuthState, fromAuth.getUser);

export const getLoginPageError = createSelector(
    getAuthState,
    fromAuth.getError
);
export const getAuthLoading = createSelector(
    getAuthState,
    fromAuth.getAuthLoading
);
export const getAuthMessage = createSelector(
    getAuthState,
    fromAuth.getAuthMessage
);

//#endregion

// ================[CART]==============================================\\
//#region CART
export const getCartState = createFeatureSelector<fromCart.State>('cart');
export const getCart = createSelector(getCartState, fromCart.getCart);
export const getErrorCartMessage = createSelector(
    getCartState,
    fromCart.getError
);
export const getCartMessage = createSelector(
    getCartState,
    fromCart.getCartMessage
);
//#endregion

// ================[Address Shipping]==============================================\\
//#region Address Shipping
export const getAddressShippingState =
    createFeatureSelector<fromAddress.State>('address');
export const getCurrentAddressShipping = createSelector(
    getAddressShippingState,
    fromAddress.getCurrentAddressShipping
);

export const getAddressesShipping = createSelector(
    getAddressShippingState,
    fromAddress.getAddressesShipping
);

export const getAddressesShippingLoading = createSelector(
    getAddressShippingState,
    fromAddress.getAddressShippingLoading
);
export const getAddressShippingError = createSelector(
    getAddressShippingState,
    fromAddress.getAddressShippingError
);
export const getAddressShippingMessage = createSelector(
    getAddressShippingState,
    fromAddress.getAddressShippingMessage
);
//#endregion

// ================[Orders]==============================================\\
//#region Orders
export const getOrdersState = createFeatureSelector<fromOrders.State>('orders');
export const getOrdersCurrent = createSelector(
    getOrdersState,
    fromOrders.getOrdersCurrent
);
export const getOrdersAll = createSelector(
    getOrdersState,
    fromOrders.getOrdersAll
);

export const getOrdersLoading = createSelector(
    getOrdersState,
    fromOrders.getOrdersLoading
);
export const getOrdersError = createSelector(
    getOrdersState,
    fromOrders.getOrdersError
);
export const getOrdersMessage = createSelector(
    getOrdersState,
    fromOrders.getOrdersMessage
);
//#endregion

// ================[Vendor]==============================================\\
//#region Vendor
export const getVendorState = createFeatureSelector<fromVendor.State>('vendor');
export const getVendorCurrent = createSelector(
    getVendorState,
    fromVendor.getVendorCurrent
);
export const getVendorsAll = createSelector(
    getVendorState,
    fromVendor.getVendorAll
);

export const getVendorLoading = createSelector(
    getVendorState,
    fromVendor.getVendorLoading
);
export const getVendorError = createSelector(
    getVendorState,
    fromVendor.getVendorError
);
export const getVendorMessage = createSelector(
    getVendorState,
    fromVendor.getVendorMessage
);
//#endregion
// ================[ Product]==============================================\\
//#region Product
export const getProductState =
    createFeatureSelector<fromProduct.State>('product');
export const getProductCurrent = createSelector(
    getProductState,
    fromProduct.getProductCurrent
);
export const getProductsAll = createSelector(
    getProductState,
    fromProduct.getProductsAll
);

export const getProductLoading = createSelector(
    getProductState,
    fromProduct.getProductLoading
);
export const getProductError = createSelector(
    getProductState,
    fromProduct.getProductError
);
export const getProductMessage = createSelector(
    getProductState,
    fromProduct.getProductMessage
);
//#endregion
// ================[ Advertising ]==============================================\\
//#region Product
export const getAdvertisingState =
    createFeatureSelector<fromAdvertising.State>('advertising');
export const getAdvertising = createSelector(
    getAdvertisingState,
    fromAdvertising.getAdvertising
);
export const getAdvertisingAll = createSelector(
    getAdvertisingState,
    fromAdvertising.getAdvertisingAll
);
export const getAdvertisingAllActive = createSelector(
    getAdvertisingState,
    fromAdvertising.getAdvertisingAllActive
);

export const getAdvertisingLoading = createSelector(
    getAdvertisingState,
    fromAdvertising.geAdvertisingLoading
);
export const getAdvertisingError = createSelector(
    getAdvertisingState,
    fromAdvertising.getAdvertisingError
);
export const getAdvertisingMessage = createSelector(
    getAdvertisingState,
    fromAdvertising.getAdvertisingMessage
);
//#endregion

//#region favorite
export const getFavoriteState =
    createFeatureSelector<fromfavorites.State>('favorites');
export const getFavorites = createSelector(
    getFavoriteState,
    fromfavorites.getfavorites
);
export const getFavoritesMessage = createSelector(
    getFavoriteState,
    fromfavorites.getMessage
);
export const getFavoritesMessageError = createSelector(
    getFavoriteState,
    fromfavorites.getError
);
//#endregion

//#region categories
export const getCategoriesState =
    createFeatureSelector<fromCategories.State>('categories');
export const getCategories = createSelector(
    getCategoriesState,
    fromCategories.getCategories
);
export const getCategory = createSelector(
    getCategoriesState,
    fromCategories.getCategory
);
//#endregion

//#region Navcategories
export const getNavCategoryState =
    createFeatureSelector<fromNavCategories.State>('navCategories');
export const getNavCategories = createSelector(
    getNavCategoryState,
    fromNavCategories.getNavCategories
);
export const getNavCategoriesLoading = createSelector(
    getNavCategoryState,
    fromNavCategories.getNavLoading
);
export const getNavCategoriesError = createSelector(
    getNavCategoryState,
    fromNavCategories.getNavError
);
export const getNavCategoriesMessage = createSelector(
    getNavCategoryState,
    fromNavCategories.getNavMessage
);
//#endregion
