import { Injectable, Inject } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";

import { AddressShippingActions, AuthActions } from "@root/core/store/actions";
import Swal from "sweetalert2";
import { AddressShippingService } from "@services/delivery-address.service";
import { DeliveryAddressModel } from "@models/delivery-address.model";
import { AddressFacadeService } from "../delivery-address-facade.service";

@Injectable()
export class AddressEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private AddressService: AddressShippingService,
        private addressFacade: AddressFacadeService
    ) {}

    loginSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loginSuccess),
                tap((e: any) => {
                    this.addressFacade.getAddressShipping(e.user.id);
                })
            ),
        { dispatch: false }
    );

    getAddressShipping$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddressShippingActions.getAddressShipping),
            switchMap(({ idUser }) =>
                this.AddressService.getAddressShipping(idUser).pipe(
                    map((addresses: DeliveryAddressModel[]) =>
                        AddressShippingActions.getAddressShippingSuccess({
                            addresses,
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            AddressShippingActions.getAddressShippingError({
                                error: error.message,
                            })
                        )
                    )
                )
            )
        )
    );

    createAddressShipping$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddressShippingActions.createAddressShipping),
            switchMap(({ address }) =>
                this.AddressService.createAddressShipping(address).pipe(
                    map((addressAll: DeliveryAddressModel[]) =>
                        AddressShippingActions.createAddressShippingSuccess({
                            addressAll
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            AddressShippingActions.getAddressShippingError({
                                error: error.message,
                            })
                        )
                    )
                )
            )
        )
    );
}
