import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserFacadeService } from "@facades/auth/user-facade.service";
import { UserModel } from "@models/user.model";
import { Subscription } from "rxjs";

import { Title } from "src/app/core/interfaces/title";
import { AddressFacadeService } from "./delivery-address-facade.service";

@Component({
    selector: "app-delivery-address",
    templateUrl: "./delivery-address.component.html",
    styleUrls: ["./delivery-address.component.scss"],
})
export class DeliveryAddressComponent implements OnInit {
    title: Title = {
        name: "Elige una dirección de entrega",
    };
    public AddressesAll;
    public user: UserModel;
    public subs: Subscription = new Subscription();
    constructor(
        private router: Router,
        public userFacade: UserFacadeService,
        public addressFacade: AddressFacadeService
    ) {}

    ngOnInit(): void {
        this.suscripciones();
    }
    suscripciones() {
        let sub: Subscription = this.userFacade
            .getCurrentUser$()
            .subscribe((user) => (this.user = user));
        this.subs.add(sub);
        let sub1: Subscription = this.addressFacade
            .getAddressesShipping$()
            .subscribe((resp) => {
                (this.AddressesAll = resp)
            });
        this.subs.add(sub1);
    }
    goToEditAddress() {
        this.router.navigateByUrl("/edit-direcciones-de-entrega");
    }
}
