import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import * as fromAppRoot from "@reducers/index";
import { UserModel } from "@models/user.model";
import { AuthActions } from "@root/core/store/actions";
import { AddressFacadeService } from "@root/features/delivery-address/delivery-address-facade.service";

import { AuthenticateModel } from "@models/security/authenticate.model";
import { SocialUser } from "@alenosmanovic91x/angularx-social-login";


@Injectable({
    providedIn: "root",
})
export class UserFacadeService {
    constructor(
        private store: Store<fromAppRoot.State>,
        private addressFacade: AddressFacadeService
    ) {}

    getErrorLogin$(): Observable<string> {
        return this.store.select(fromAppRoot.getLoginPageError);
    }

    getUserMessages$(): Observable<string> {
        return this.store.select(fromAppRoot.getAuthMessage);
    }
    getCurrentUser$(): Observable<UserModel> {
        return this.store.select(fromAppRoot.getUser);
    }
    isLoggedIn$(): Observable<boolean> {
        return this.store.select(fromAppRoot.getLoggedIn);
    }
    isLoadingAuth$(): Observable<boolean> {
        return this.store.select(fromAppRoot.getAuthLoading);
    }
    loginWithCredential(credential: AuthenticateModel) {
        this.store.dispatch(AuthActions.login({ credential }));
    }
    createUser(user: UserModel) {
        this.store.dispatch(AuthActions.createUser({ user }));
    }
    logout() {
        this.store.dispatch(AuthActions.logout());
    }
    updateProfile(user: UserModel) {
        this.store.dispatch(AuthActions.updateUserProfile({ user }));
    }
    setUser(user: UserModel) {
        this.store.dispatch(AuthActions.setUser({ user }));
        this.addressFacade.getAddressShipping(user.id);
    }
    loginWithgoogle(credential: SocialUser ) {
        this.store.dispatch(AuthActions.loginWithGoogle({ credential }));
    }
    loginWithFacebook(credential: SocialUser ) {
        this.store.dispatch(AuthActions.loginWithFaceBook({ credential }));
    }
}
