import { createAction, props } from "@ngrx/store";
import { AdvertisingModel } from "@models/advertising-model";

export enum AdvertisingActionType {
    Load = "[Advertising Component] Load",
    SearchAdvertisings = "[Advertising Component] Search Advertising",
    SearchAllActiveAdvertisings = "[Advertising Component] Search All Active Advertising",
    SearchAllActiveAdvertisingsSuccess = "[Advertising Component] Search All Active Advertising Success" ,
    SearchAllAdvertisings = "[Advertising Component] Search All Advertising",
    SearchAllAdvertisingsSuccess = "[Advertising Component] Search All Advertising Success",
    UpdateAdvertising = "[Advertising Component] Update Advertising",
    CreateAdvertising = "[Advertising Component] Create Advertising",
    UpdateTotal = "[Advertising Component] Update Total",
    ActionFailure = "[Advertising API] Execute action failure",
    ActionSuccess = "[Advertising API] Execute action success",
    LoadSuccess = "[Advertising API] Load Success",
    Refresh = "[Advertising Page] Refresh",
    Selected = "[Advertising Page] Select",
    SubmitSuccess = "[Advertising API] Submit Success",
}

export const load = createAction(AdvertisingActionType.Load);
export const searchAdvertisings = createAction(
    AdvertisingActionType.SearchAdvertisings,
    props<{ query: AdvertisingModel }>()
);

export const searchAllActiveAdvertisings = createAction(
    AdvertisingActionType.SearchAllActiveAdvertisings,
);

export const searchAllActiveAdvertisingsSuccess = createAction(
    AdvertisingActionType.SearchAllActiveAdvertisingsSuccess,
    props<{ advertisingAll: AdvertisingModel[] }>()
);

export const searchAllAdvertisings = createAction(
    AdvertisingActionType.SearchAllAdvertisings,
);
export const searchAllAdvertisingsSuccess = createAction(
    AdvertisingActionType.SearchAllAdvertisingsSuccess,
    props<{ advertisingAll: AdvertisingModel[] }>()
);

export const createAdvertising = createAction(
    AdvertisingActionType.CreateAdvertising,
    props<{ advertising: AdvertisingModel; ref: any }>()
);

export const actionFailure = createAction(
    AdvertisingActionType.ActionFailure,
    props<{ error: string }>()
);

export const actionSuccess = createAction(
    AdvertisingActionType.ActionSuccess,
    props<{ msg: string }>()
);

export const updateTotal = createAction(
    AdvertisingActionType.UpdateTotal,
    props<{ total: number }>()
);

export const loadSuccess = createAction(
    AdvertisingActionType.LoadSuccess,
    props<{ advertisings: AdvertisingModel[] }>()
);

export const submitSuccess = createAction(
    AdvertisingActionType.SubmitSuccess,
    props<{ msg: string }>()
);

export const refresh = createAction(AdvertisingActionType.Refresh);

export const select = createAction(
    AdvertisingActionType.Selected,
    props<{ selectedAdvertisingId: string | number }>()
);

export const updateAdvertising = createAction(
    AdvertisingActionType.UpdateAdvertising,
    props<{ advertising: AdvertisingModel; ref: any }>()
);
