import { Action, createReducer, on } from "@ngrx/store";

import { UserModel } from "@models/user.model";
import { CartModel } from "@models/cart.model";
import { AuthActions, CartActions, UserProfilerActions } from "../actions";

export interface State {
    user: UserModel | null;
    loggedIn: boolean;
    error: string | null;
    loading: boolean;
    message: string | null;
}

export const initialState: State = {
    user: null,
    loggedIn: false,
    error: null,
    loading: false,
    message: null,

};

const authReducer = createReducer(
    initialState,
    //#region
    //===================>[AUTENTICACION]
    on(AuthActions.login, (state, { credential }) => ({
        ...state,
        error: null,
        loading: true,
    })),
    on(AuthActions.loginSuccess, (state, { user }) => ({
        ...state,
        error: null,
        loading: false,
        user,
        loggedIn: true,
    })),
    on(AuthActions.loginWithGoogle, (state) => ({
        ...state,
        loading: true,
    })),
    on(AuthActions.loginWithGoogleSuccess, (state, { user }) => ({
        ...state,
        loading: false,
        user,
        loggedIn: true,
    })),
    on(AuthActions.setUser, (state, { user }) => ({
        ...state,
        user,
        loading: false,
    })),
    on(AuthActions.loginWithOffice, (state) => ({
        ...state,
        error: null,
        loading: true,
    })),
    on(AuthActions.createUser, (state) => ({
        ...state,
        error: null,
        loading: true,

    })),
    on(AuthActions.createUserSuccess, (state, {resp}) => ({
        ...state,
        error: null,
        loading: false,
        message: resp.message[0] || "Usuario Creado."
    })),

    on(AuthActions.loginOfficeRestoreSucces, (state, { user }) => ({
        ...state,
        error: null,
        loading: false,
        user,
        loggedIn: true,
    })),
    on(AuthActions.logoutSuccess, (state) => ({ ...state, ...initialState })),
    on(AuthActions.loginFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    })),
    on(AuthActions.updateUserProfile, (state) => ({
        ...updateFlags(state),
    })),
    on(AuthActions.updateUserProfileSuccess, (state, { user }) => ({
        ...state,
        user,
        error: null,
        loading: false,
        message: 'Usuario Actualizado.'
    })),
    on(AuthActions.error, (state, {error}) => ({
        ...updateFlags(state),
        error
    })),
);

const updateFlags = (state: State): State => {
    return {
        ...state,
        error: null,
        loading: true,
        message: null,
    };
};

export function reducer(state: State | undefined, action: Action) {
    return authReducer(state, action);
}
export const getLoggedIn = (state: State) => state.loggedIn;
export const getUser = (state: State) => state.user;
export const getADUserS = (state: State) => state.user;
export const getError = (state: State) => state.error;
export const getAuthLoading = (state: State) => state.loading;
export const getAuthMessage = (state: State) => state.message;
//#endregion
