import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { AuthService } from "@services/auth";
import { MessageService } from "@services/message.service";

@Component({
    selector: "app-form-new-password",
    templateUrl: "./form-new-password.component.html",
    styleUrls: ["./form-new-password.component.scss"],
})
export class FormNewPasswordComponent implements OnInit {
    public formulario: UntypedFormGroup;
    public isLinear = false;
    public firstFormGroup: UntypedFormGroup;
    public secondFormGroup: UntypedFormGroup;
    public emailEnviado: boolean = false;
    public mensajeError: string;
    public mensajeFinal: string;
    constructor(
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private mensajeService: MessageService
    ) {
        this.formulario = fb.group({
            email: new UntypedFormControl(null, [
                Validators.required,
                Validators.email,
            ]),
            password: new UntypedFormControl(null, [
                Validators.required,
                Validators.minLength(6),
            ]),
            token: new UntypedFormControl(null, [Validators.required]),
        });
    }

    ngOnInit(): void {
        this.firstFormGroup = this.fb.group({
            firstCtrl: ["", [Validators.required, Validators.email]],
        });

        this.secondFormGroup = this.fb.group({
            secondCtrl: ["", Validators.required],
        });
    }

    guardar() {
        let values = this.formulario.value;
        this.authService.saveNewPass$(values).subscribe(
            (resp) => {
                if (resp.success) {
                    this.mensajeFinal = resp.message;
                    this.mensajeService.showCustom(
                        resp.message,
                        null,
                        "success"
                    );
                } else {
                    this.mensajeFinal = resp.message;
                    this.mensajeService.showCustom(resp.message, null, "error");
                }
            },
            (error: HttpErrorResponse) => {
                this.mensajeFinal = error.error.message;
                this.mensajeService.showCustom(
                    error.error.message,
                    null,
                    "error"
                );
            }
        );
    }
    resetPass() {
        let credenciales = this.firstFormGroup.value;
        let email = { email: credenciales.firstCtrl };
        this.authService.resetPass$(email).subscribe(
            (resp) => {
                this.emailEnviado = resp.success;
                if (resp.success) {
                    this.mensajeError = null;
                    this.mensajeService.showCustom(
                        resp.message,
                        null,
                        "success"
                    );
                } else {
                    this.mensajeService.showCustom(resp.message, null, "error");
                    this.mensajeError = resp.message;
                }
            },
            (error: HttpErrorResponse) => {
                this.emailEnviado = false;
                this.mensajeError = error.error.message;
                this.mensajeService.showCustom(
                    error.error.message,
                    null,
                    "error"
                );
            }
        );
    }
}
