import { Injectable, Inject } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { Router } from "@angular/router";
import { OrdersActions } from "@root/core/store/actions";
import { OrderService } from "@services/orders.service";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { CartOrderResponseModel } from "@models/cart-order-response.model";

@Injectable()
export class OrdersEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private orderService: OrderService
    ) {}
    getOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrdersActions.getOrder),
            switchMap(({ code }) =>
                this.orderService.getOrder(code).pipe(
                    map((order: CartOrderResponseModel) =>
                        OrdersActions.getOrderSuccess({ order })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            OrdersActions.getErrorOrders({
                                error: error.message,
                            })
                        )
                    )
                )
            )
        )
    );
}
