import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import * as fromAppRoot from "@reducers/index";
import { OrderModel } from "@models/order.model";
import { OrdersActions } from "@root/core/store/actions";


@Injectable({
    providedIn: "root",
})
export class OrdersFacadeService {
    constructor(private store: Store<fromAppRoot.State>) {}

    getOrdersCurrent$(): Observable<OrderModel> {
        return this.store.select(fromAppRoot.getOrdersCurrent);
    }
    setOrdersToPay$(order: OrderModel): void {
        this.store.dispatch(OrdersActions.setOrder({ order }));
    }
    getOrder(code: string): void {
        this.store.dispatch(OrdersActions.getOrder({ code }));
    }
}
