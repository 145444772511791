import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent  {
  @Input() colorsAviables: any[] = [];
  @Output() colorSelected: EventEmitter<string> = new EventEmitter()
  onClick(colorSelected: string) {
    this.colorSelected.emit(colorSelected);
  }
}
