<div class="assigned-consultant-wrapper page-container">
    <app-title [title]="title"></app-title>
    <div class="consultant-content">
        <div class="consultant-items">
            <div class="consultant-img">
                <img src="assets/img/user-profile.png" alt="">
                <div class="consultant-info">
                    <span>{{ 'assigned-consultant.message' | translate }}</span>
                    <span>{{ 'assigned-consultant.message2' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="consultant-chat">
        <button>
            <span>{{ 'assigned-consultant.alert' | translate }}</span>
            <img src="assets/img/whatsapp.png" alt="">
            <span class="consultant-speak">{{ 'assigned-consultant.speak' | translate }}</span>
        </button>
    </div>
</div>
