<div class="items-wrapper">
    <div class="items-info">
        <div class="items-content">
            <div class="items-contents">
                <div class="items">
                    <img src="/assets/img/sliders/company-1.png" alt="">
                    <div class="company-name"><span>IUSA</span></div>
                    <div class="items-city"><span>Medellin Antioquia</span></div>
                </div>
                <div class="items-text">
                    <div class="calification"><a href="">Calificación Pendiente</a></div>
                    <div class="text-info"><span class="delivery">Inicio</span></div>
                    <div class="text-info-date"><span>Miércoles, 12 de Abril de 2019</span></div>
                    <div class="text-info1"><span>08:29:06 a.m.</span></div>
                </div>
                <div class="items-text-send">
                    <div class="text-info"><span class="delivery">Entrega</span></div>
                    <div class="text-info-date"><span>Jueves, 13 de Abril de 2019</span></div>
                    <div class="text-info1"><span>10:30:06 a.m.</span></div>
                    <div class="address"><span>Calle 23 Sur # 45 b -23</span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="items-send">
        <span class="delivery">Entrega</span>
        <span>Tarjeta de crédito
            <img src="/assets/img/pay-visa.png" alt="">
        </span>
    </div>
    <div class="totals">
        <div class="totals-container">
            <div class="total-item">
                <span class="label">SubTotal</span>
                <span class="value">$90.000.000</span>
            </div>
            <div class="total-item">
                <span class="label">IVA</span>
                <span class="value">0.00</span>
            </div>
            <div class="total-item total">
                <span class="label-total">TOTAL</span>
                <span class="value-total">$90.000.000</span>
            </div>
            <img src="/assets/img/logo-coordinadora.png" alt="">
            <div class="total-item">
                <span class="label">Flete</span>
                <span class="value">.000.000</span>
            </div>
            <div class="total-item">
                <span class="label-total total">TOTAL</span>
                <span class="value-total total">90.000.000</span>
            </div>
        </div>
    </div>
</div>
