<div class="pad-top"></div>
<div class="guide-info-wrapper page-container">
    <app-trace-guide></app-trace-guide>
    <div class="guide-info-content">
        <div class="guide-items">
            <div class="guide-info-order">
                <span>{{ 'guide-info.order' | translate }}</span>
                <span class="guide-text">{{ 'guide-info.date' | translate }}</span>
            </div>
            <div class="guide-info-order">
                <span class="guide-text">{{ 'guide-info.serial' | translate }}</span>
            </div>
            <div class="guide-info-order-subtitle">
                <span>{{ 'guide-info.order-in-warehouse' | translate }}</span>
            </div>
            <div class="guide-info-order-total">
                <span>{{ 'guide-info.city' | translate }}</span>
                <span class="guide-total">{{ 'guide-info.total' | translate }}</span>
            </div>
        </div>
    </div>
</div>
