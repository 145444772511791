<mat-dialog-content class="product-add mat-typography">
    <div class="product-title">
        <img src="/assets/img/giovanye/desktop/si.png" alt="">
        <span>Producto agregado exitosamente</span>
    </div>

    <div class="wrapper-card product-add">
        <div class="product-container">
            <div class="content">
                <div class="img-product-add">
                    <img src="{{ item.img }}" alt="{{ item.name }}">
                </div>

                <div>
                    <div class="info-product-content">
                        <div class="product-info">
                            <span>{{ item.name }}</span>
                            <span>{{ item.price | moneda}}</span>
                            <span>{{ item.ref }}</span>
                        </div>
                        <div class="input-product">
                            <label for="" class="">Cantidad</label>
                            <input type="number" disabled name="" id="" min="1" max="9999999" value="{{item.qty}}">
                        </div>
                        <!-- <a class="delete-product" href="">Eliminar del carrito</a> -->
                    </div>
                </div>
            </div>

        </div>
    </div>


    <div class="actions">
        <button  aria-label="Ir al acrrito" class="actions__cart" routerLink="/carrito-de-compras" (click)="onClose()">
            Ver mi carrito de compras
        </button>
        <button mat-dialog-close class="btnclose" (click)="onClose()"><span>Seguir comprando</span></button>
    </div>
</mat-dialog-content>
