import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'whatsapp-float',
  templateUrl: './whatsapp-float.component.html',
  styleUrls: ['./whatsapp-float.component.scss']
})
export class WhatsappFloatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
