import { ActivatedRoute, Params } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import { productListData } from "../../test-helpers/products";
import { Title } from "src/app/core/interfaces/title";
import { ProductsService } from "@services/products.service";
import { UtilsService } from "@root/utilities/utils.service";
import { catchError } from "rxjs/operators";

@Component({
    selector: "app-featured-products",
    templateUrl: "./featured-products.component.html",
    styleUrls: ["./featured-products.component.scss"],
})
export class FeaturedProductsComponent implements OnInit {
    // products = productListData;
    products = undefined;
    title: Title = {
        name: "Productos destacados",
    };

    constructor(
        private route: ActivatedRoute,
        private productService: ProductsService,
        private utilservice:  UtilsService
    ) {}

    ngOnInit(): void {
        this.utilservice.loading(true);
        this.productService.getTopProducts().subscribe((resp) => {
            this.products = resp;
            this.utilservice.loading(false);
        })
        ;
    }
}
