<div class="pad-top"></div>
<div class="frequent-question-wrapper page-container">
    <app-title [title]="title"></app-title>
    <div class="frequent-question">
        <div class="frequent-question-title">
            <span>1- Lorem ipsum</span>
        </div>
        <div class="frequent-question-text">
            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod rerum perferendis a
                officiis eveniet. Voluptatum, repellendus! Ut molestiae quis rerum tempore dolor exercitationem nisi!
                Accusamus deleniti quo laborum excepturi. Voluptas.</span>
        </div>
        <div class="frequent-question-title">
            <span>2- Lorem ipsum</span>
        </div>
        <div class="frequent-question-text">
            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas quasi sunt necessitatibus ratione
                exercitationem similique natus sint a obcaecati labore tempore porro esse mollitia, numquam soluta
                aspernatur ex? Recusandae, officia.
            </span>
        </div>
        <div class="frequent-question-title">
            <span>3- Lorem ipsum</span>
        </div>
        <div class="frequent-question-text">
            <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eum excepturi earum perspiciatis deleniti
                unde eligendi quod consectetur necessitatibus ea consequatur aut mollitia illum magni dolor quia in
                esse, sequi adipisci.
            </span>
        </div>
        <div class="frequent-question-title">
            <span>4- Lorem ipsum</span>
        </div>
        <div class="frequent-question-text">
            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam itaque modi suscipit. Dolorum saepe
                odio ipsum sint nobis vel sequi nulla, aliquid repudiandae reiciendis quas facere ducimus, optio facilis
                aliquam. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Labore fuga laudantium magnam, rerum
                neque dicta quod natus odio ut illum at ratione tempora quia, odit repudiandae beatae quisquam tempore
                doloribus?
            </span>
        </div>
    </div>
</div>
