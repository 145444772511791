import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAppRoot from '@reducers/index';
import { UserModel } from '@models/user.model';
import { CategoriesActions } from '@root/core/store/actions';
import { CategoryInterface } from './store/categories.actions';

@Injectable({
  providedIn: 'root',
})
export class CategoriesFacadeService {
  constructor(private store: Store<fromAppRoot.State>) { }
  setCategoriesInStore(categories: CategoryInterface[]) {
    this.store.dispatch(
      CategoriesActions.setCategoriesInStore({ categories })
    );
  }
  setCurrentCategory(category: CategoryInterface) {
    this.store.dispatch(CategoriesActions.setCurrentCategory({ category }));
  }
  getCurrentCategory$(): Observable<CategoryInterface> {
    return this.store.select(fromAppRoot.getCategory);
  }
  getCategories$(): Observable<CategoryInterface[]> {
    return this.store.select(fromAppRoot.getCategories);
  }
}
