import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAppRoot from '@reducers/index';
import { navActions } from '@root/core/store/actions';
import { CategoriesResponse } from '@interfaces/categories.response.interface';

@Injectable({
    providedIn: 'root',
})
export class NavFacadeService {
    constructor(private store: Store<fromAppRoot.State>) {}
    getNavCategories(): void {
        this.store.dispatch(navActions.getProductsFromDB());
    }
    getNavcategoriesFromStore$(): Observable<CategoriesResponse> {
        return this.store.select(fromAppRoot.getNavCategories);
    }
 }
