import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'moneda',
})
export class MonedaPipe implements PipeTransform {
    transform(value: number, ...args: unknown[]): string {
        if (!value) return '0';
        let options = {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        };
        let valor: string = value.toLocaleString('es-CO', options);
        return valor;
    }
}
