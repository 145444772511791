import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/core/interfaces/featuredproducts';

@Component({
  selector: 'app-last-purchases-items',
  templateUrl: './last-purchases-items.component.html',
  styleUrls: ['./last-purchases-items.component.scss']
})
export class LastPurchasesItemsComponent implements OnInit {
  @Input() product: Product;
  constructor() { }

  ngOnInit(): void {
  }

}
