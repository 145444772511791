<div *ngIf="show">
  <div>
    <app-banner-info></app-banner-info>
  </div>
  <div>
    <app-purchase-banner-beta></app-purchase-banner-beta>
  </div>
  <div>
    <app-subscribe></app-subscribe>
  </div>
  <div>
    <app-footer-links-policies></app-footer-links-policies>
  </div>
  <div>
    <app-footer-social-networks></app-footer-social-networks>
  </div>
  <div>
    <app-copyright class=""></app-copyright>
  </div>
</div>
