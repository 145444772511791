import { CartItemModel } from '@models/cart-item.model';
import { Action, createReducer, on } from '@ngrx/store';

import { CartActions } from '../../../core/store/actions';

export interface State {
  cart: CartItemModel[];
  error: string | null;
  loading: boolean;
  message: string | null;
}

export const initialState: State = {
  cart: [],
  error: null,
  loading: false,
  message: null,
};

const cartReducer = createReducer(
  initialState,
  //#region Cart Old
  on(CartActions.addProductCart, (state) => ({
    ...updateFlags(state),
    error: null,
    loading: true,
  })),
  on(CartActions.setCartItems, (state, { cartItems }) => ({
    ...updateFlags(state),
    error: null,
    loading: false,
    cartItems,
  })),
  on(CartActions.createCart, (state) => ({
    cart: null,
    ...updateFlags(state),
  })),
  on(CartActions.searchCart, (state) => ({
    ...updateFlags(state),
    error: null,
    loading: true,
  })),
  on(CartActions.carReset, (state) => ({
    ...updateFlags(state),
    error: null,
    loading: false,
    cartItems: null,
  })),
  on(CartActions.searchCartSuccess, (state, { cartItems }) => ({
    ...updateFlags(state),
    message: 'Carrito Actualizado.',
    loading: false,
    cartItems,
  })),

  on(CartActions.searchCartUser, (state) => ({
    ...updateFlags(state),
    error: null,
    loading: true,
  })),
  on(CartActions.searchCartUserSuccess, (state, { carts }) => ({
    ...updateFlags(state),
    loading: false,
    cartAll: carts,
  })),
  on(CartActions.deleteCartAllItem, (state) => updateFlags(state)),
  on(CartActions.deleteCartAllItemSuccess, (state, { msg }) => ({
    ...state,
    message: msg,
    cartItems: [],
  })),

  on(CartActions.deleteCartItem, (state) => updateFlags(state)),

  on(CartActions.updateCart, (state) => ({
    ...updateFlags(state),
    cart: null,
  })),
  on(CartActions.updateCartItem, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CartActions.updateCartItemSuccess, (state, { cart }) => ({
    ...updateFlags(state),
    cart: {
      ...state.cart,
      items: cart,
    },
  })),
  on(CartActions.cartError, (state, { error }) => ({
    ...updateFlags(state),
    error,
    loading: false,
  })),
  on(CartActions.confirmPurchase, (state) => ({
    ...updateFlags(state),
    error: null,
    loading: true,
  })),
  on(CartActions.getCartCode, (state) => ({
    ...updateFlags(state),
  })),
  on(CartActions.getCartCodeSuccess, (state, { cartAll }) => ({
    ...updateFlags(state),
    cartAll,
  })),
  on(CartActions.emptyShoppingcart, (state) => ({
    ...updateFlags(state),
    cart: null,
  })),
  //#endregion

  //#region Cart Temp
  on(CartActions.addProductCart, (state, { item }) => {
    let exist = false;
    let cart = [];
    state.cart.forEach((i) => {
      let newItem = { ...i };
      if (i.id === item.id) {
        newItem.qty += item.qty;
        exist = true;
      }
      cart.push(newItem);
    });
    if (!exist) {
      cart.push(item);
    }
    return {
      ...updateFlags(state),
      cart,
      message: item
        ? 'Producto agregado al Carrito de Compras'
        : 'No se agregó',
    };
  }),
  on(CartActions.deleteTemporalCartItem, (state, { index }) => {
    const cart = [...state.cart];
    cart.splice(index, 1);
    return {
      ...updateFlags(state),
      cart,
      message: 'Item eliminado del Carrito de Compras',
    };
  }),
  on(CartActions.deleteTemporalCartAllItem, (state) => {
    return {
      ...updateFlags(state),
      cart: [],
      message: null,
    };
  }),
  on(CartActions.clearCartMessage, (state) => {
    return {
      ...updateFlags(state),
    };
  })


  //#endregion
);

const updateFlags = (state: State): State => {
  return {
    ...state,
    error: null,
    loading: false,
    message: null,
  };
};

export function CartReducer(state: State | undefined, action: Action) {
  return cartReducer(state, action);
}
export const getError = (state: State) => state.error;
export const getAuthLoading = (state: State) => state.loading;
export const getCart = (state: State) => state.cart;
export const getCartMessage = (state: State) => state.message;
//#endregion
