<div class="last-purchases-wrapper page-container">
    <app-title [title]="title"></app-title>
    <div class="products">
        <app-last-purchases-items></app-last-purchases-items>
        <div class="content">
            <div class="title">
                <span>Resumen</span>
            </div>
            <div class="products-wrapper">
               <!-- <div class="product-item" *ngFor="let product of products">
                    <app-products-card [product]="product"></app-products-card>
                </div>-->
                <div class="cart-list-wrapper">
                    <ul class="cart-wrapper-item" role="list">
                        <li class="cart-item">
                            <div class="cart-body">
                                <div class="cart-img">
                                    <img src="assets/img/sliders/company-1.png" alt="">
                                </div>
                                <div class="cart-items">
                                    <div class="product-name">Cable THHN 500 MCM</div>
                                    <div class="product-ref">163556</div>
                                    <div class="product-price-wrapper">
                                        <span class="product-price">$45.000</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="cart-item">
                            <div class="cart-body">
                                <div class="cart-img">
                                    <img src="assets/img/sliders/company-1.png" alt="">
                                </div>
                                <div class="cart-items">
                                    <div class="product-name">Cable THHN 500 MCM</div>
                                    <div class="product-ref">163556</div>
                                    <div class="product-price-wrapper">
                                        <span class="product-price">$45.000</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="cart-item">
                            <div class="cart-body">
                                <div class="cart-img">
                                    <img src="assets/img/sliders/company-1.png" alt="">
                                </div>
                                <div class="cart-items">
                                    <div class="product-name">Cable THHN 500 MCM</div>
                                    <div class="product-ref">163556</div>
                                    <div class="product-price-wrapper">
                                        <span class="product-price">$45.000</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="cart-item">
                            <div class="cart-body">
                                <div class="cart-img">
                                    <img src="assets/img/sliders/company-1.png" alt="">
                                </div>
                                <div class="cart-items">
                                    <div class="product-name">Cable THHN 500 MCM</div>
                                    <div class="product-ref">163556</div>
                                    <div class="product-price-wrapper">
                                        <span class="product-price">$45.000</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="cart-item">
                            <div class="cart-body">
                                <div class="cart-img">
                                    <img src="assets/img/sliders/company-1.png" alt="">
                                </div>
                                <div class="cart-items">
                                    <div class="product-name">Cable THHN 500 MCM</div>
                                    <div class="product-ref">163556</div>
                                    <div class="product-price-wrapper">
                                        <span class="product-price">$45.000</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="cart-item">
                            <div class="cart-body">
                                <div class="cart-img">
                                    <img src="assets/img/sliders/company-1.png" alt="">
                                </div>
                                <div class="cart-items">
                                    <div class="product-name">Cable THHN 500 MCM</div>
                                    <div class="product-ref">163556</div>
                                    <div class="product-price-wrapper">
                                        <span class="product-price">$45.000</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="cart-item">
                            <div class="cart-body">
                                <div class="cart-img">
                                    <img src="assets/img/sliders/company-1.png" alt="">
                                </div>
                                <div class="cart-items">
                                    <div class="product-name">Cable THHN 500 MCM</div>
                                    <div class="product-ref">163556</div>
                                    <div class="product-price-wrapper">
                                        <span class="product-price">$45.000</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="actions">
                <button>¿Necesitas ayuda?</button>
            </div>
        </div>

    </div>
</div>
