import { Component, Input, OnInit } from '@angular/core';
import { productConstants } from '@root/core/constants';
import { promoBannerMocks } from '@root/core/mocks/promo-banner-mocks';

@Component({
  selector: 'app-purchase-banner-beta',
  templateUrl: './purchase-banner-beta.component.html',
  styleUrls: ['./purchase-banner-beta.component.scss']
})
export class PurchaseBannerBetaComponent implements OnInit {
  
  public send = productConstants.SEND;
  public priceSend = productConstants.PRICE_SEND;
  public textBuy = productConstants.TEXT_BUY;
  public textDcto  = productConstants.TEXT_DCTO;
  public textMultiples= productConstants.TEXT_MULTIPLES;
  public pays = productConstants.PAYS;

  @Input() bannerPurchase = promoBannerMocks;

  constructor() { }

  ngOnInit(): void {
  }

}
