import { Component, OnInit } from '@angular/core';
import { footerMenuListData } from '@root/test-helpers/footer-list';
import { FooterMenuList } from '../footer/footer-list.interface';

@Component({
  selector: 'app-footer-links-policies',
  templateUrl: './footer-links-policies.component.html',
  styleUrls: ['./footer-links-policies.component.scss']
})
export class FooterLinksPoliciesComponent implements OnInit {
    footerList: FooterMenuList[] = footerMenuListData;
  constructor() { }

  ngOnInit(): void {
  }

}
