import { FavoriteFacadeService } from './favorites-facade.service';
import { FavoriteService } from './../../../../core/services/favorite.service';
import { Injectable, Inject } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { Router } from "@angular/router";
import { favoritesActions } from "@root/core/store/actions";
import { OrderService } from "@services/orders.service";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";
import { switchMap, map, catchError, tap } from "rxjs/operators";
import { CartOrderResponseModel } from "@models/cart-order-response.model";
import { UserProfileService } from "@services/user-profile.service";
import { UserModel } from "@models/user.model";

@Injectable()
export class FavoritesEffects {
    constructor(
        private actions$: Actions,
        private favoriteService: FavoriteService,
        private favoriteFacade: FavoriteFacadeService
    ) {}
    setFavorite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(favoritesActions.setFavorites),
            switchMap(({ productsId }) =>
                this.favoriteService.addOrRemoveFavorite$(productsId).pipe(
                    map((response) =>
                       {
                           return favoritesActions.setFavoritesSuccess({response})
                        }
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            favoritesActions.error({
                                error: error.message,
                            })
                        )
                    )
                )
            )
        )
    );
    // setFavoriteSucces$ = createEffect(() =>
    // this.actions$.pipe(
    //     ofType(favoritesActions.setFavoritesSuccess),
    //     tap((user: any) =>this.favoriteFacade.getFavoriteFromDB(user.id),
    //             catchError((error: HttpErrorResponse) =>
    //                 of(
    //                     favoritesActions.error({
    //                         error: error.message,
    //                     })
    //                 )
    //             )
    //         )
    //     )
    // );
    getFavoriteFromDB$ = createEffect(() =>
        this.actions$.pipe(
            ofType(favoritesActions.getFavoritesFromDB),
            switchMap(({ userId }) =>
                this.favoriteService.getfavoriteFromDB$(userId).pipe(
                    map((products: any) =>
                        favoritesActions.getFavoritesFromDBSuccess({products})
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            favoritesActions.error({
                                error: error.message,
                            })
                        )
                    )
                )
            )
        )
    );
}
