import { get } from 'lodash';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    catchError,
    concatMap,
    map,
    switchMap,
    mergeMap,
} from 'rxjs/operators';

import * as vendorActions from './vendor-actions';
import { VendorService } from '@services/vendors.service';
import { VendorModel } from '@models/vendor.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class VendorStoreEffects {
    constructor(
        private actions$: Actions,
        private vendorService: VendorService
    ) {}

    searchVendorsEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(vendorActions.searchVendors),
            switchMap(({}) =>
                this.vendorService.getAllVendor().pipe(
                    map((vendorAll: VendorModel[]) =>
                        vendorActions.searchVendorsSuccess({ vendorAll })
                    ),
                    catchError((error: any) =>
                        of(vendorActions.actionFailure({ error }))
                    )
                )
            )
        )
    );
    refreshEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(vendorActions.refresh),
            map((_) => vendorActions.load())
        )
    );
}
