<div class="filter-product-list-wrapper">
    <ul class="filter-product-wrapper" role="list">
        <li class="filter-product-item">
            <a href="#">
                <i class="fas fa-th icon-item"></i>
                <span class="filter-product-name">{{ 'filter-product.grid' | translate }}</span>
            </a>
        </li>
        <li class="filter-product-item">
            <a href="#">
                <span class="filter-product-name">{{ 'filter-product.filter1' | translate }}</span>
            </a>
        </li>
        <li class="filter-product-item">
            <a href="#">
                <span class="filter-product-name">{{ 'filter-product.filter2' | translate }}</span>
            </a>
        </li>
    </ul>
</div>
