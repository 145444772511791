import { ISliderPromo } from "@interfaces/slider-promo-interface";

export const promoBannerMocks:ISliderPromo[]  = [
    {
        id:1,
        promo:"Compra",
        promo1:"100% segura",
        img:"../../../assets/img/giovanye/desktop/compra.png"
    },
    {
        id:1,
        promo:"Múltiples",
        promo1:"Medios de pagos",
        img:"../../../assets/img/giovanye/desktop/medios_pago.png"
    },
    {
        id:1,
        promo:"Envíos gratis",
        promo1:"$150.000",
        img:"../../../assets/img/giovanye/desktop/envios.png"
    },
    // {
    //     id:1,
    //     promo:"Métodos de pago",
    //     promo1:"Sistecrédito",
    //     img:"../../../assets/img/giovanye/desktop/sistecredito.png"
    // },

]
