import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor() { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers;
    const API_URL = environment.API_URL;
    let token =
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImI1MmI3ODgxZWIxZWJmN2Q5OGFlZmE3ZGY2ZGExMDZiMjg0OTllYThiZTc2ZmZjZjEyNmM5NGE4ZjZlYzdiMGI0NTdiODkwYTBjMWYzMmQ5In0.eyJhdWQiOiIyIiwianRpIjoiYjUyYjc4ODFlYjFlYmY3ZDk4YWVmYTdkZjZkYTEwNmIyODQ5OWVhOGJlNzZmZmNmMTI2Yzk0YThmNmVjN2IwYjQ1N2I4OTBhMGMxZjMyZDkiLCJpYXQiOjE2MTA1NDc1MDcsIm5iZiI6MTYxMDU0NzUwNywiZXhwIjoxNjExODQzNTA3LCJzdWIiOiIxMjIiLCJzY29wZXMiOltdfQ.eBpRiglpowTqhlm4KtjD-BPfPNiR5HPLWU_WOLa46xAT_tDJETxzqHuJltcODg9pF4IeQ09_87ND3AX6o1lqcUtcN5QnRbLeNg42FZJ7CgmFXedm24vwK7Ma4fgKIrbp0NBLTfd0p12zu6DURxW4s78tYBH5NYjEL0NRAhKEgcyf2xbgzacEHGGthA7e6NVttCYZpQWjKLipCEjwwtQ1jJJ0INKMhPCPPqbmrnDD3erroCU_XVOknW_0_QAUG5XB_n4pLoSUR1dqkdlbw4EKpS6rlEd23pXYvCBU8JQfZhRYgO-swRkzwV6lqwTl4KET5q4z39711zsJLbQOUCUyw8K5fCM49QtLL4B5h3jtDo_JDELLuYACDZOIlY9xkeSzujthhQtGweL1kSXk1JhejA-qNcELekjuFumHL7BJOvyPfnj3a0UY9A2l0WFSX27KuiVGosM_DEpymYHmCiUEAafqH9sCCcC6rRQdSbzOjUSb8lCE2qmTP2LzWxm67oL0CSnilpic-al0drBuK_jwxCMy7-UuFpqqSVQ8Zv7AJhLrxUdH-7OOtCkk5Gg2tMmAmTACZ8_P0Rf2ab9jeDUbeTKAzCoYjpyjNuO0ui6N6PDgzR--nKndIe-sTJ0fOEK7zeZwCp_IKgAkz2YrM7P94cTm1kBTp0A-xMbu_mpsZYM';
    token =
      JSON.parse(localStorage.getItem('user'))?.token?.access_token ||
      token;
    if (req.url.indexOf(environment.API_URL) !== -1) {
      switch (req.url) {

        case API_URL + 'users/password/request-token':
          headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            Fuente: 'web',
          });
          break;
        case API_URL + 'users/password/set-new-password':
          headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Fuente: 'web',
            Authorization: 'Bearer ' + token,
          });
          break;
        case API_URL + 'users':
          headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
            Fuente: 'web',
          });
          break;
        case API_URL + 'sell-with-us':
          headers = new HttpHeaders({
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
            Fuente: 'web',
          });
          break;
        default:
          if (req.url.indexOf(environment.API_URL + 'login') == -1) {
            headers = new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
              Fuente: 'web',
            });
            if (req.url.indexOf('favorite') !== -1) {
              headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                Fuente: 'web',
              });
            } else if (req.url.indexOf(API_URL + 'users/') !== -1) {
              headers = new HttpHeaders({
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + token,
                Fuente: 'web',
              });
            }
          }
      }
    }

    const REQ_CLONE = req.clone({
      headers,
    });
    return next.handle(REQ_CLONE).pipe(catchError(this.manejarError));
  }
  manejarError(error: HttpErrorResponse) {
   // return of(error);
    return throwError(() =>error);
  }
}

