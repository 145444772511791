import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'gvy-sizes-buttons',
  templateUrl: './sizes-buttons.component.html',
  styleUrls: ['./sizes-buttons.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule
  ]
})
export class SizesButtonsComponent {
  @Input() public sizes = [
    { id: 1, size: 'S' },
    { id: 2, size: 'M' },
    { id: 3, size: 'L' },
    { id: 4, size: 'XL' },
  ]
  @Output() onSelected = new EventEmitter()
  constructor() {

  }

  seleted(item: any) {
    this.onSelected.emit(item)
  }
}
