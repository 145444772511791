import { IMenuCategory } from "@interfaces/menu-categories-interfaces";


export const menuCategoryMocks:IMenuCategory[]  = [
    {
        image: "../../../assets/img/giovanye/desktop/jumbotron.png",
    },
    {
        image: "../../../assets/img/giovanye/desktop/jumbotron_2.png",
    }
    ,
    {
        image: "../../../assets/img/giovanye/desktop/jumbotron.png",
    }
    ,


]
