<footer class="container footer-wrapper">
    <div class="footer-content">
        <div class="footer-information {{footerListItem.class}} font-text" *ngFor="let footerListItem of footerList">
            <h3>{{ footerListItem.header }}</h3>
            <ul>
                <li *ngFor="let item of footerListItem.items">
                    <a routerLink="{{ item.link }}" >{{  item.label
                        }}</a>
                </li>
            </ul>
        </div>
    </div>

</footer>
