import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CategoryModel } from '@models/category.model';
import { UserModel } from '@models/user.model';

@Injectable({
    providedIn: 'root',
})
export class UserProfileService {
    urlApi: string = environment.API_URL;
    token =
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjgxNzA1ZWE5ZjRlMmI1NTMxZDJjNWIxYWI1ZWVkNzdmZWFjODI2MjA2NDg3M2M3M2ZhZWNmMTYyYWIzOGRiNTNiODkyN2NjZjAwMjQ5MzBlIn0.eyJhdWQiOiIyIiwianRpIjoiODE3MDVlYTlmNGUyYjU1MzFkMmM1YjFhYjVlZWQ3N2ZlYWM4MjYyMDY0ODczYzczZmFlY2YxNjJhYjM4ZGI1M2I4OTI3Y2NmMDAyNDkzMGUiLCJpYXQiOjE2MTk3ODgzMDgsIm5iZiI6MTYxOTc4ODMwOCwiZXhwIjoxOTM1MTQ4MzA4LCJzdWIiOiIxMjIiLCJzY29wZXMiOltdfQ.KdN8qJ7XpqLd3DMcM439vCGVezQRl3Qsgb7FY_pDlcu-fet1DD9fY11c_u0S1NWmYz7subeiNQnacahC4SIYLhWJc2VvWuI9duN1PAhRf422V8MXlLLGEuK0AgFRxSZ6Ey8fQ9V-d8Cb8X77DEn9JilECbKo_4MvKoig5llcWPNI4PhHTuR9JYX2_XNt2ZRzzZChX6QtxSf6hXsiyJUoJ1VENLLihsNRZocHAGPEaz9GtIwaPLmVc4eYrWwBltKwwFzZGqqFqXCFvnfhJ3kYIG_wpJaWTV9K6zarl_LX23FThMWp_QXkCHYXmhqv-PLXzCDqvPNmyrJOPOZU6XqWt3vi6wNnmYc8zs2Pjxc3YFKblOHpf9ep-uv4cYKuTeDan24i2RtEmOUqjLIey-kd5mNf7LdhLKWzPlF_4jmIkVK1tRCfhvg8HIxY4VwgA4jmRbyHWWh9VA-r9Ax5ZGSLY1jOACNxZmmqym-2A5f2j9lIy3UQaIL1G_2v2WSPi2HtOq32sFGO0UPNqnKSDk-fEYJHiEAxMZrrUiurNCXEMcGcrnLt2csdbpUaUSXHwv4TkTsRyo0T4RwcnZbZ_bWqZP7otU-ry7hi3D779fV1l0NDWYmx-o1DQuz4DE6kq3c5yVdlvm9QJFJqkZQdnsNrLmPshN0iwI-ClPbYAERDofs';

    constructor(private http: HttpClient) {}
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.token,
            mimeType: 'multipart/form-data',
        }),
    };
    public updateUserProfile$(user: UserModel): Observable<any> {
        let formdata = new FormData();
        formdata.append('_method', 'PUT');
        formdata.append('name', user.name);
        formdata.append('birthday', '' + user.birthday);
        formdata.append('is_juristic', user.is_juristic == true ? '1' : '0');
        formdata.append('cc', user.cc ? user.cc : '0');
        formdata.append('company', user.company);
        formdata.append('email', user.email);
        formdata.append('gender', user.gender);
        formdata.append('job', user.job);
        formdata.append('last_name', user.last_name);
        formdata.append('nit', user.nit ? user.nit : '0');
        formdata.append('phone', user.phone);
        formdata.append('document_type', user.document_type);

        if (
            user.chamber_commerce &&
            (user.chamber_commerce.type === 'image/jpg' ||
                user.chamber_commerce.type === 'image/png' ||
                user.chamber_commerce.type === 'image/jpeg')
        ) {
            formdata.append(
                'chamber_commerce',
                user.chamber_commerce,
                user.chamber_commerce.name
            );
        }
        if (
            user.image &&
            (user.image.type === 'image/jpg' ||
                user.image.type === 'image/png' ||
                user.image.type === 'image/jpeg')
        ) {
            formdata.append('image', user.image, user.image.name);
        }
        if (
            user.representative_id &&
            (user.representative_id.type === 'image/jpg' ||
                user.representative_id.type === 'image/png' ||
                user.representative_id.type === 'image/jpeg')
        ) {
            formdata.append(
                'representative_id',
                user.representative_id,
                user.representative_id.name
            );
        }
        if (
            user.rut &&
            (user.rut.type === 'image/jpg' ||
                user.rut.type === 'image/png' ||
                user.rut.type === 'image/jpeg')
        ) {
            formdata.append('rut', user.rut, user.rut.name);
        }

        return this.http
            .put<any>(`${this.urlApi}users/${user.id}`, formdata)
            .pipe(map((e: any) => e.data));
    }

    public createSuscribete(Email: any) {
        let formdata = new FormData();
        formdata.append('_method', 'PUT');
        formdata.append('name', Email.name);
        formdata.append('last_name', Email.last_name);
        formdata.append('email', Email.email);
        formdata.append('role_id', '2');
        formdata.append('password', Email.email);
        formdata.append('password_confirmation', Email.email);
        const url = `${this.urlApi}users/suscribe`;
        return this.http.post<any>(url, formdata);
    }
}
