import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductsService } from '@services/products.service';
import { Product } from '@interfaces/featuredproducts';

@Component({
    selector: 'app-featured-products-slider',
    templateUrl: './featured-products-slider.component.html',
    styleUrls: ['./featured-products-slider.component.scss'],
})
export class FeaturedProductsSliderComponent implements OnInit {
    @Input() product: Product;
    public category: string;
    public tallas = ['S', 'M', 'L', 'XL'];
    customOptions: OwlOptions = {
        loop: true,
        autoplay: true,
        center: false,
        dots: false,
        autoHeight: true,
        autoWidth: true,
        nav: true,
        navText: [
            '<i class="fa-solid fa-arrow-left-long"></i>',
            '<i class="fa-solid fa-arrow-right-long"></i>',
        ],
        responsive: {
            0: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 4,
            },
        },
    };
    sliders = [];
    carouselTitle: string;
    constructor(private productoService: ProductsService) {}

    ngOnInit(): void {
        // this.getData();
    }
    getProductsFeatured() {
        this.productoService.mostSelledProdutc().subscribe((productAll) => {
            this.sliders = productAll;
        });
    }
    getData(productAll?: any[]) {
        if (!productAll) {
            this.getProductsFeatured();
        }
        this.sliders = productAll;
    }
    /**
     * Tooggle favorite state
     * @param sliderItem slider item
     */
    toggleFavorite(sliderItem: any): void {
        sliderItem.isFavorite = !sliderItem.isFavorite;
    }
}
