import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
    catchError,
    map,
    switchMap,
} from "rxjs/operators";

import { AdvertisingService } from "@services/advertising.service";
import { advertisingActions } from "@root/core/store/actions";

@Injectable()
export class AdvertisingEffects {
    constructor(
        private dataService: AdvertisingService,
        private actions$: Actions
    ) {}

    searchAdvertisingsAllEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(advertisingActions.searchAllAdvertisings),
            switchMap(() =>
                this.dataService.getAdvertisingAll$().pipe(
                    map((advertisingAll) =>
                        advertisingActions.searchAllAdvertisingsSuccess({
                            advertisingAll,
                        })
                    ),
                    catchError((error) =>
                        of(advertisingActions.actionFailure({ error }))
                    )
                )
            )
        )
    );
}
