import { UtilsService } from './../../../utilities/utils.service';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UserFacadeService } from '@facades/auth/user-facade.service';
import { CartDeleteItemModel } from '@models/cart-delete-item.model';
import { CartModel } from '@models/cart.model';
import { UserModel } from '@models/user.model';
import { MessageService } from '@services/message.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { CartUpdateItemsModel } from '@models/cart-update-items.model';
import { CartItemModel } from '@models/cart-item.model';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CartFacadeService } from '@root/pages/cart/cart-facade.service';

@Component({
  selector: 'app-cart-items',
  templateUrl: './cart-items.component.html',
  styleUrls: ['./cart-items.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class CartItemsComponent implements OnInit, OnDestroy {
  @Input() company: any[];
  subs: Subscription = new Subscription();
  cart: CartModel;
  user: UserModel;
  @Input() buttonDisable: boolean;
  ItemsByUpdate: CartUpdateItemsModel;
  @Output() changes: EventEmitter<string> = new EventEmitter();
  @Output() colorsAviables: EventEmitter<any[]> = new EventEmitter();
  @Output() sizesAviables: EventEmitter<any[]> = new EventEmitter();
  isLinear = false;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;

  constructor(
    private cartFacade: CartFacadeService,
    private userFacade: UserFacadeService,
    private messageService: MessageService,
    private utilsService: UtilsService,
    private _formBuilder: UntypedFormBuilder
  ) { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.getData();

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
  }
  getData() {
    const sub2: Subscription = this.userFacade
      .getCurrentUser$()
      .subscribe((user) => (this.user = user));
    this.subs.add(sub2);
    const sub3: Subscription = this.cartFacade
      .getCartMessage$()
      .subscribe((mensaje) => {
        if (mensaje && mensaje !== '') {
          this.utilsService.loading(false);
          this.buttonDisable = false;
          this.messageService.showCustom(mensaje, null, 'success');
          this.cartFacade.cleanMesssageCart();
        }
      });
    this.subs.add(sub3);
    const sub4: Subscription = this.cartFacade
      .getCartMessageError$()
      .subscribe((mensaje) => {
        if (mensaje && mensaje !== '') {
          this.messageService.showCustom(mensaje, null, 'error');
        }
      });
    this.subs.add(sub4);
  }

  deleleItem(id, itemDeleted) {
    Swal.fire({
      title: 'ADVERTENCIA',
      text: `Está a punto de borrar: ${itemDeleted.qty} unidad(es) de ${itemDeleted.name}, ¿Esta seguro de continuar?`,
      showDenyButton: true,
      confirmButtonText: `Si`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.cartFacade.removeItemTemporalCart(id);
      } else if (result.isDenied) {
      }
    });
    // }
  }

  updateQty(quantity: number, items: CartItemModel): void {
    if (items.qty + quantity <= 0) {
      this.messageService.showCustom(
        'No puede bajar más, si desea eliminarlo haga click en la X.',
        null,
        'warn'
      );
      return;
    }
    const qty = quantity;
    const itemToUpdate = {
      ...items,
      qty,
    };
    // items.qty = items.qty + qty;
    // this.ItemsByUpdate = {
    //     _method: 'PATCH',
    //     code: this.cart.code,
    //     product_id: items.product_id,
    //     user_id: this.user.id,
    //     qty: items.qty + qty,
    //     type_of_sale: items.type_of_sale,
    // };
    this.buttonDisable = true;
    this.utilsService.loading();
    this.cartFacade.addProduct(itemToUpdate);
    setTimeout(() => this.utilsService.loading(false), 500);
    this.buttonDisable = false;
  }

  public buttonDisableAction(event) {
    this.buttonDisable = event;
  }
  change(type: string, colors: any[], sizes: any[]){
    this.changes.emit(type)
    this.colorsAviables.emit(colors)
    this.sizesAviables.emit(sizes)
  }
}
