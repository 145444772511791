import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import Swal from "sweetalert2";

import * as fromAppRoot from "@reducers/index";
import { AddressShippingActions } from "@root/core/store/actions";
import { DeliveryAddressModel } from "@models/delivery-address.model";

@Injectable({
    providedIn: "root",
})
export class AddressFacadeService {
    constructor(private store: Store<fromAppRoot.State>) {}
    getCurrentAddressShipping$(): Observable<DeliveryAddressModel> {
        return this.store.select(fromAppRoot.getCurrentAddressShipping);
    }
    getAddressShipping(idUser: number): void {
        this.store.dispatch(
            AddressShippingActions.getAddressShipping({ idUser })
        );
    }
    setAddressShipping(address: DeliveryAddressModel): void {
        this.store.dispatch(
            AddressShippingActions.setAddressShipping({ address })
        );
    }
    getAddressesShipping$(): Observable<DeliveryAddressModel[]> {
        return this.store.select(fromAppRoot.getAddressesShipping);
    }

    createAddressShipping(address: DeliveryAddressModel): void {
        this.store.dispatch(
            AddressShippingActions.createAddressShipping({ address })
        );
    }

    getMessage$(): Observable<string> {
        return this.store.select(fromAppRoot.getAddressShippingMessage);
    }
    getMessageError$(): Observable<string> {
        return this.store.select(fromAppRoot.getAddressShippingError);
    }
}
