<div class="wrapper">
    <div class="purchases container-lg imgdos">
        <div class="purchases-content compras" *ngFor="let item of bannerPurchase" >
            <div class="img-purchases">

                <img src="{{ item.img }}" class="img-offers img-fluid" alt="">
            </div>
            <div class="text-purchases">
                <span class="arial">{{ item.promo }} <br> <strong>{{ item.promo1}} </strong></span>

            </div>
        </div>
        <a class="purchases-content compras" href="https://www.sistecredito.com/" rel="noopener noreferrer" target="_blank">
            <div class="img-purchases">

                <img src="assets/img/giovanye/desktop/sistecredito.png" class="img-offers img-fluid" alt="">
            </div>
            <div class="text-purchases">
                <span>Método de pago <strong>Sistecrédito</strong></span>

            </div>
          </a>

        <!-- <div class="purchases-content col-4 col-md-4">
            <div class="img-purchases">
                <img src="assets/img/giovanye/mobile/tarjeta-de-credito.png" class="img-mobile-offers" alt="">
                <img src="assets/img/giovanye/desktop/tarjeta-de-credito.png" class="img-offers" alt="">
            </div>
            <div class="text-purchases">
                <span>{{ textMultiples }} <strong> {{ pays }}</strong></span>
            </div>
        </div>

        <div class="purchases-content col-4 col-md-4">
            <div class="img-purchases">
                <img src="assets/img/giovanye/mobile/enviado.png" class="img-mobile-offers" alt="">
                <img src="assets/img/giovanye/desktop/enviado.png" class="img-offers" alt="">
            </div>
            <div class="text-purchases">
                     <span class="">{{ send }} <strong>{{ priceSend}}</strong></span>
            </div>
        </div>

        <div class="purchases-content col-4 col-md-4">
            <div class="img-purchases">
                <img src="assets/img/giovanye/mobile/enviado.png" class="img-mobile-offers" alt="">
                <img src="assets/img/giovanye/desktop/enviado.png" class="img-offers" alt="">
            </div>
            <div class="text-purchases">
                     <span class="">{{ send }} <strong>{{ priceSend}}</strong></span>
            </div>
        </div>
         -->
    </div>
</div>
