export const TEXT_CATEGORY = "Categorías de producto"
export const PRICE = "Precio"
export const SIZES = "Tallas"
export const PRICES = "Desde 30.000 - 90.000"
export const COLORS = "Colores"
export const ORDER = "Ordenar por"
export const CATEGORY_PRODUCT = "Categorías de producto seleccionado"
export const DESC = "15% Desc,"
export const OFFER = "La oferta terminara a las"
export const SIZE = "Tallas disponibles"
export const GUIDE_SIZE = "Guía de tallas"
export const QUANTITY = "Cantidad"
export const  ADD_CART = "Agregar a mi carrito"
export const SHARED = "Comparte en:"
export const DELIVERY ="Despacho a domicilio"
export const AVAILABLE = "Disponible"
export const WITHDRAWAL = "Retiro en tienda"
export const TEXT ="Frase vendedora para incentivar la venta"
export const TEXT_DESCRIPTION= "  Es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias el año."
export const CHOOSE_COLOR = "Elige tu color:"
export const INTEREST= "También podría interesarte"
export const RATINGS = "Valoraciones"
export const DELETE_TITLE = "Deseas eliminar el producto de la lista de deseos"
export const CANCEL = "Cancelar"
export const ACCEPT = "Si"
export const DATE = "Añadido a la fecha: 3/05/2021"
export const SIZES_BUST= "Busto"
export const SIZES_DESCRIPTION_BUST = "Envuelve la cinta métrica alrededor de la aparte más sobresaliente del busto, pasando por debajo de los brazos"
export const WAIST ="Cintura"
export const SIZES_DESCRIPTION_WAIST="Envuelve la cinta métrica de la línea natural de la cintura. Para hallar esta línea, inclina tu brazo de forma lateral hasta tocar tu rodilla; el pliegue que se forma es el punto deonde debes tomar el miedo."
export const HIP = "Cadera"
export const SIZES_DESCRIPTION_HIP= "Envuelve la cinta métrica alrededor de la parte más sobresaliente de tus caderas, para esto mantén tus pies juntos."
export const SEND = "Envíos gratis"
export const PRICE_SEND = 100000
export const TEXT_BUY = "Compra"
export const TEXT_DCTO = "100% segura"
export const TEXT_MULTIPLES = "Múltiples medios"
export const PAYS = "De pagos"
