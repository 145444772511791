import { Component, OnInit } from '@angular/core';
import { bannerInfoMocks } from '@root/core/mocks/banner-info-mock';

@Component({
  selector: 'app-banner-info',
  templateUrl: './banner-info.component.html',
  styleUrls: ['./banner-info.component.scss']
})
export class BannerInfoComponent implements OnInit {

    public bannerText = bannerInfoMocks;

  constructor() { }

  ngOnInit(): void {
  }

}
