import { createAction, props } from '@ngrx/store';

import { UserModel } from '@models/user.model';
import { AuthenticateModel } from '@models/security/authenticate.model';
import { SocialUser } from '@alenosmanovic91x/angularx-social-login';




export const login = createAction(
    '[Auth] Login',
    props<{ credential: AuthenticateModel }>()
);

export const loginSuccess = createAction(
    '[Auth] Login Success',
    props<{ user: UserModel }>()
);

export const loginWithOffice = createAction(
    '[Auth] Login with office'
)

export const logoutWithOffice = createAction(
    '[Auth] Logout with office'
)

export const loginOfficeRestore = createAction(
    '[Auth] Login office restore'
)

export const loginOfficeRestoreSucces = createAction(
    '[Auth] Login office restore success',
    props<{ user: UserModel }>()

)

export const loginFailure = createAction(
    '[Auth] Login Failure',
    props<{ error: string | null }>()
);

export const setIsAuth = createAction(
    '[Auth] Set Is Authenticated',
    props<{ payload: boolean }>()
  );

export const logout = createAction(
    '[Auth] Logout',
);

export const logoutSuccess = createAction(
    '[Auth] Logout Success',
);

export const createUser = createAction(
    '[Auth] Create User',
    props<{ user: UserModel }>()
);
export const createUserSuccess = createAction(
    '[Auth] Create User Success',
    props<{ resp: any }>()
);
export const updateUserProfile = createAction(
    "[Auth] Update User",
    props<{ user: UserModel }>()
);
export const updateUserProfileSuccess = createAction(
    "[Auth] Update User Success",
    props<{ user: UserModel }>()
);
export const error = createAction(
    "[Auth] User Error",
    props<{ error: any }>()
);
export const setUser = createAction(
    '[Auth] Set User',
    props<{ user: UserModel }>()
);



export const loginWithGoogle = createAction(
    '[Auth] Login With Google',
    props<{ credential: SocialUser }>()
);

export const loginWithGoogleSuccess = createAction(
    '[Auth] Login With Google Success',
    props<{ user: UserModel }>()
);

export const loginWithFaceBook = createAction(
    '[Auth] Login With Faceebook ',
    props<{ credential: SocialUser }>()
);
export const loginWithFaceBookSuccess = createAction(
    '[Auth] Login With Facebook Success',
    props<{ user: UserModel }>()
);