import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { navActions } from '@root/core/store/actions';
import { CartModel } from '@models/cart.model';
import { UtilitiesApisService } from '@services/utilities.apis.service';
import { CategoriesResponse } from '@interfaces/categories.response.interface';

@Injectable()
export class NavEffects {
    cartDetail;

    vendorId;
    constructor(
        private actions$: Actions,
        private utilService: UtilitiesApisService
    ) {}

    getNavProductCategoryEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(navActions.getProductsFromDB),
            switchMap((_) =>
                this.utilService.getNavProductsCategories$().pipe(
                    map((category: CategoriesResponse) =>
                        navActions.getProductsFromDBSuccess({ category })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(navActions.error({ error }))
                    )
                )
            )
        )
    );
}
