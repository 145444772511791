import { Component, OnInit } from '@angular/core';
import { Title } from 'src/app/core/interfaces/title';

@Component({
  selector: 'app-trace-guide',
  templateUrl: './trace-guide.component.html',
  styleUrls: ['./trace-guide.component.scss']
})
export class TraceGuideComponent implements OnInit {
  title: Title=  {
    name: 'Rastrear Guía'
  };
  constructor() { }

  ngOnInit(): void {
  }

}
