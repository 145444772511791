<div class="container font-text copyright-content">
    <ul>
        <li><a aria-label="Ir al inicio"   routerLink="/">{{ 'copyright.nit' | translate }} </a></li>
        <li><a aria-label="Ir al inicio"  routerLink="/">{{ 'copyright.bonus' | translate }} </a></li>
        <li><a aria-label="Ir localiza tu tienda"  routerLink="//localiza-tu-tienda">{{ 'copyright.map' | translate }} </a></li>
        <li><a aria-label="Ir terminos "  routerLink="/terms">{{ 'copyright.terms' | translate }} </a> </li>
        <li><a aria-label="Ir uso de cookies"  routerLink="/cookies">{{ 'copyright.policies' | translate }}</a> </li>
        <li><a aria-label="Ir terminos"  routerLink="/terms">{{ 'copyright.policies-data' | translate }} </a> </li>
    </ul>

        <span>Dirección: Cra 52 # 14 -200 &nbsp; bodegas Olaya herrera &nbsp; Medellín,&nbsp; Colombia&nbsp; Tel: &nbsp;6042659252</span>
        <span>Copyright &copy; {{ anio }} <a href="https://matelsoft.com/" target="_blank"
            rel="noopener noreferrer">Matelsoft</a></span>

</div>






<!--
<div class="copyright-by">
    <span>Copyright &copy; {{ anio }} <a href="https://matelsoft.com/" target="_blank"
        rel="noopener noreferrer">Matelsoft</a></span>
</div> -->
