import { environment } from "./../../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CartOrderResponseModel } from "@models/cart-order-response.model";

@Injectable({
    providedIn: "root",
})
export class OrderService {
    urlApi: string = environment.API_URL;
    storage: string = environment.STORAGE;
    constructor(private http: HttpClient) {}
    public getOrdersByState(userId: number): Observable<any> {
        return this.http.get<any>(
            `${this.urlApi}orders/${userId}/orders-state`
        );
    }

    public getOrder(code: string): Observable<CartOrderResponseModel> {
        return this.http.get<CartOrderResponseModel>(
            `${this.urlApi}orders/${code}`
        );
    }
}
