import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavService {
    toggleMenuEvent = new Subject<boolean>();
    isOpen = false;

    constructor() {
    }

    /**
     * Toggle isOpen property
     */
    toggleMenu(): void {
        this.isOpen = !this.isOpen;
        this.toggleMenuEvent.next(this.isOpen);
    }
}
