import { Injectable } from '@angular/core';
import { appConstants } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setItem(key, item) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  getItem(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  removeItem(key: string) {
    localStorage.removeItem(key)
  }

  removeAll() {
    localStorage.clear();
  }

  getToken() {
    return JSON.parse(localStorage.getItem('user')).token.access_token;
}
}
