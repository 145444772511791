import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import * as fromAppRoot from "@reducers/index";
import { vendorActions } from "@root/core/store/actions";
import { VendorModel } from "@models/vendor.model";


@Injectable({
    providedIn: "root",
})
export class VendorFacadeService {
    constructor(private store: Store<fromAppRoot.State>) {}

    getCurrentVendor$(): Observable<VendorModel> {
        return this.store.select(fromAppRoot.getVendorCurrent);
    }

    getAllVendors() {
        this.store.dispatch(vendorActions.searchVendors());
    }

    selectVendor(id: number) {
        let vendorsAll;
        this.store.select(fromAppRoot.getVendorsAll).subscribe( vendorAll => vendorsAll = vendorAll)
        let vendor: VendorModel = vendorsAll.find( e => e.id==id)

         this.store.dispatch(vendorActions.selectVendor({vendor}));
    }
}
