import { IProductDescription } from '@interfaces/product-description-interfaces';

export const productDescriptionMocks: IProductDescription[] = [
  {
    titleDescription: 'CONSEJO SOBRE TALLA / TAMAÑO',
    textDescription:
      'Tenga en cuenta que todos los productos GIOVANYE se muestran con talles latinos. La Guía de Tallas sirve como indicador para ayudarle a encontrar la talla correcta. En caso de dudas con respecto a la talla, Revisa tu guía de tallas para ayudarte a entender',
  },
  {
    titleDescription: 'ENVÍO Y DEVOLUCIONES',
    textDescription:
      'Tenga en cuenta que todos los productos GIOVANYE se muestran con talles latinos. La Guía de Tallas sirve como indicador para ayudarle a encontrar la talla correcta. En caso de dudas con respecto a la talla, Revisa tu guía de tallas para ayudarte a entender',
    list: 'No haber sido usada la prenda. Contar con todas sus etiquetas. No encontrarse sucia. No tener rastros de uso de desodorantes o maquillaje con químicos que decoloren o deterioren las telas. No tener rastros de uso de químicos o blanqueador. No presentar desteñido. La prenda no debió ser alterada o modificada por el consumidor. La horma de la prenda debe encontrarse perfecta. El estampado, apliques o bordados no deben tener inconsistencias por lavado, planchado o adulteración. Encontrarse en las mismas condiciones en las que fue adquirida. Deben conservar la etiqueta original. Sugerimos que se pruebe las prendas antes de retirarla. Debe presentar el certificado de regalo o indicar el número de cédula del comprador. La compra debió realizarse en una tienda física GIOVANYE o en la tienda Online giovanye.co o por medio de WhatsApp en línea 3194014927 Para los cambios de prendas debe tener presente que:',
    subTitleDescription:
      '¿La política de cambios, así como sus condiciones son una obligación legal de la Compañía?',
    subText:
      'No. La política de cambios no es una obligación legal que deba cumplir GIOVANYE, sin embargo, para la misma es muy importante la satisfacción de sus clientes, por lo que les permite cambiar sus prendas entro de un plazo determinado, siempre y cuando cumpla ciertos requisitos y bajo unas condiciones particulares.',
  },
];
