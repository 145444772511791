import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';


import { CartActions } from '@root/core/store/actions';

import Swal from 'sweetalert2';
import { CartModel } from '@models/cart.model';
import { CartService } from '@services/cart.service';
import { CartContentModel } from '@models/cart-content.model';
import { OrdersFacadeService } from '@root/features/orders/orders-facade.service';
import { CartFacadeService } from '../cart-facade.service';

@Injectable()
export class CartEffects {
    cartDetail;

    vendorId;
    constructor(
        private actions$: Actions,
        private cartService: CartService,
        private router: Router,
        private orderFacadeService: OrdersFacadeService,
        private cartFacade: CartFacadeService
    ) {}

    getCarCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.createCart),
            switchMap(({ cart }) =>
                this.cartService.getCarCode(cart).pipe(
                    map((cart: CartModel) =>
                        CartActions.createCartSuccess({ cart })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(CartActions.cartError({ error: error.message }))
                    )
                )
            )
        )
    );

    getCarCodeSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CartActions.createCartSuccess),
                tap((e: any) => {
                    Swal.fire({
                        title: 'CartCode',
                        icon: 'success',
                        text: e,
                    });
                })
            ),
        { dispatch: false }
    );

    getCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.searchCart),
            switchMap(({ cart }) =>
                this.cartService.getCart$(cart.code).pipe(
                    map((cartItems: CartContentModel) =>
                        CartActions.searchCartSuccess({ cartItems })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(CartActions.cartError({ error: error.message }))
                    )
                )
            )
        )
    );
    getCarCodeByCartType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.getCartCode),
            switchMap(({ params }) =>
                this.cartService.getCarCodeII(params).pipe(
                    map((cartAll) =>
                        CartActions.getCartCodeSuccess({ cartAll })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(CartActions.cartError({ error: error.message }))
                    )
                )
            )
        )
    );

    // AddProductCart$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(CartActions.addProductCart),
    //         tap(({ cart }) => (this.cartDetail = cart)),
    //         switchMap(({ cart }) =>
    //             this.cartService.addProductCart$(cart).pipe(
    //                 map((cart: any) =>
    //                     CartActions.addProductCartSuccess({ cart })
    //                 ),
    //                 tap(({ cart }) => {
    //                     let cartD: CartModel = {
    //                         id: null,
    //                         user_id: this.cartDetail.user_id,
    //                         cart_type: cart.items[0].type_of_sale,
    //                         vendor_id: cart.items[0].vendor_id,
    //                         code: this.cartDetail.code,
    //                         subtotal_tax: cart.totals.taxes,
    //                         subtotal_price: cart.totals.subtotal,
    //                         shipping_cost: null,
    //                         carrier_id: null,
    //                         total: cart.totals.total,
    //                         status: null,
    //                         shipping_quoted: null,
    //                         shipping_paid: null,
    //                         updated_at: null,
    //                         created_at: null,
    //                         vendor_name: "string",
    //                         vendor_image: "string",
    //                         items: cart.items,
    //                     };
    //                     this.cartFacade.selectCart(cartD);
    //                 }),
    //                 // tap((e) => {
    //                 //     this.cartFacade.searchCartUser(this.cartDetail.user_id);
    //                 // }),
    //                 catchError((error: HttpErrorResponse) =>
    //                     of(CartActions.cartError({ error: error.message }))
    //                 )
    //             )
    //         )
    //     )
    // );

    getCartUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.searchCartUser),

            switchMap(({ userId }) =>
                this.cartService.getCartUser$(userId).pipe(
                    map((carts) =>
                        CartActions.searchCartUserSuccess({ carts })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(CartActions.cartError({ error: error.message }))
                    )
                )
            )
        )
    );
    confirmPurchase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.confirmPurchase),
            tap(({ purchase }) => {
                if (purchase.vendor_id) {
                    this.vendorId = purchase.vendor_id;
                }
            }),
            switchMap(({ purchase }) =>
                this.cartService.cartConfirmShopping$(purchase).pipe(
                    map((order) =>
                        CartActions.confirmPurchaseSuccess({ order })
                    ),
                    // tap(({ order }) => {
                    //     if (purchase.order_type === 1) {
                    //         this.cartFacade.deleteAllItemTemporaCart();
                    //         this.router.navigateByUrl(
                    //             `articulos/${this.vendorId}`
                    //         );
                    //     } else {
                    //         this.orderFacadeService.setOrdersToPay$(order);
                    //         this.router.navigateByUrl("/pago");
                    //     }
                    // }),
                    catchError((error: HttpErrorResponse) =>
                        of(CartActions.cartError({ error: error.message }))
                    )
                )
            )
        )
    );
    confirmPurchaseSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CartActions.confirmPurchaseSuccess),
                tap(({ order }) => {
                    if (order.totals) {
                        this.cartFacade.deleteAllItemTemporaCart();
                        this.orderFacadeService.setOrdersToPay$(order);
                        this.router.navigateByUrl('/pago');
                    }
                })
            ),
        { dispatch: false }
    );
    deleteCartItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.deleteCartItem),
            switchMap(({ item }) => {
                return this.cartService.deleteCartItem$(item).pipe(
                    map((resp) =>
                        CartActions.deleteCartItemSuccess({ item, resp })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(CartActions.cartError({ error: error.message }))
                    )
                );
            })
        )
    );

    deleteCartAllItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.deleteCartAllItem),
            switchMap(({ item }) => {
                return this.cartService.deleteCartAllItem$(item).pipe(
                    map((msg) => CartActions.deleteCartAllItemSuccess({ msg })),
                    catchError((error: HttpErrorResponse) =>
                        of(CartActions.cartError({ error: error.message }))
                    )
                );
            })
        )
    );

    updateCartItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CartActions.updateCartItem),
            switchMap(({ item }) => {
                return this.cartService.updateCartItem$(item).pipe(
                    map((cart) => CartActions.updateCartItemSuccess({ cart })),
                    catchError((error: HttpErrorResponse) =>
                        of(CartActions.cartError({ error: error.message }))
                    )
                );
            })
        )
    );
}
