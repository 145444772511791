import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from 'src/app/core/interfaces/title';
import { productListData } from '../../test-helpers/products';

@Component({
  selector: 'app-last-purchases-resumen',
  templateUrl: './last-purchases-resumen.component.html',
  styleUrls: ['./last-purchases-resumen.component.scss']
})
export class LastPurchasesResumenComponent implements OnInit {
  products = productListData;
  title: Title=  {
    icon: 'assets/img/user/user-menu-purchasing-management-icon.png',
    name: 'Últimas compras'
  };

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

}
