import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss'],
})
export class NotifierComponent implements OnInit {
  public icon: string = 'info';
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<NotifierComponent>
  ) { }

  ngOnInit(): void {
    switch (this.data.type) {
      case 'success':
        this.icon = 'done_all';
        break;
      case 'warn':
        this.icon = 'info';
        break;
      case 'error':
        this.icon = 'error_outline';
        break;
      case 'giovanye':
        this.icon = 'notifications';
        break;
      default:
        this.icon = 'info';
    }
  }
}

/*
ICONOS OPCIONALES
=====================
SUCCESS
=====================
check_circle_outline
done
done_all
done_outline
thumb_up
=====================
INFO
=====================
notifications
=====================
WARN
=====================
contact_support
feedback
help
help_outline
=====================
ERROR
=====================
pan_tool
info
report_problem
verified_user
new_releases
not_interested
report

*/
