import { Component, OnInit } from '@angular/core';
import { Title } from 'src/app/core/interfaces/title';

@Component({
  selector: 'app-guide-not-located',
  templateUrl: './guide-not-located.component.html',
  styleUrls: ['./guide-not-located.component.scss']
})
export class GuideNotLocatedComponent implements OnInit {
  title: Title=  {
    name: 'Rastrear Guía'
  };
  constructor() { }

  ngOnInit(): void {
  }

}
