import { AdvertisingModel } from "@models/advertising-model";
import { Action, createReducer, on } from "@ngrx/store";
import { advertisingActions } from "@root/core/store/actions";

export interface State {
    advertising: AdvertisingModel;
    advertisingAll: AdvertisingModel[];
    advertisingAllActive: AdvertisingModel[];
    error: string | null;
    loading: boolean;
    message: string | null;
}

export const initialState: State = {
    advertising: null,
    advertisingAll: [],
    advertisingAllActive:[],
    error: null,
    loading: false,
    message: null,
};

const advertisingReducer = createReducer(
    initialState,
    on(advertisingActions.load, (state) => ({
        ...state,
        isLoading: true,
        error: null,
    })),
    on(advertisingActions.searchAdvertisings, (state) => ({
        ...state,
        isLoading: true,
        error: null,
    })),
    on(advertisingActions.searchAllActiveAdvertisings, (state) => ({
        ...state,
        isLoading: true,
        error: null,
    })),
    on(
        advertisingActions.searchAllActiveAdvertisingsSuccess,
        (state, { advertisingAll }) => ({
            ...state,
            advertisingAllActive: advertisingAll,
            isLoading: false,
            error: null,
        })
    ),
    on(advertisingActions.searchAllAdvertisings, (state) => ({
        ...state,
        isLoading: true,
        error: null,
    })),
    on(
        advertisingActions.searchAllAdvertisingsSuccess,
        (state, { advertisingAll }) => ({
            ...state,
            advertisingAll,
            isLoading: false,
            error: null,
        })
    ),
    on(advertisingActions.updateTotal, (state, { total }) => {
        return { ...state, total };
    }),
    on(advertisingActions.actionFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    })),
    on(advertisingActions.select, (state, { selectedAdvertisingId }) => {
        return {
            ...state,
            selectedAdvertisingId,
        };
    })
);

export function reducer(state: State | undefined, action: Action) {
    return advertisingReducer(state, action);
}
export const getAdvertisingError = (state: State) => state.error;
export const geAdvertisingLoading = (state: State) => state.loading;
export const getAdvertisingMessage = (state: State) => state.message;

export const getAdvertising = (state: State) => state.advertising;
export const getAdvertisingAll = (state: State) => state.advertisingAll;
export const getAdvertisingAllActive = (state: State) => state.advertisingAllActive;
