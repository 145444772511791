import { Action, createReducer, on } from "@ngrx/store";

import { OrdersActions } from "@root/core/store/actions";
import { OrderModel } from "@models/order.model";

export interface State {
    order: OrderModel;
    ordersAll: OrderModel[];
    error: string | null;
    loading: boolean;
    message: string | null;
}

export const initialState: State = {
    order: null,
    ordersAll: [],
    error: null,
    loading: false,
    message: null,
};

const ordersReducer = createReducer(
    initialState,
    //#region
    //===================>[Address Shipping]
    on(OrdersActions.getOrder, (state) => ({
        ...state,
        error: null,
        loading: true,
    })),
    on(OrdersActions.getOrderSuccess, (state, { order }) => ({
        ...state,
        error: null,
        loading: false,
        order,
    })),
    on(OrdersActions.setOrder, (state, { order }) => ({
        ...state,
        error: null,
        loading: false,
        order,
    })),
    on(OrdersActions.getErrorOrders, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }))
    //#region
    //===================>[Address Shipping]
);

const updateFlags = (state: State): State => {
    return {
        ...state,
        error: null,
        loading: true,
        message: null,
    };
};

export function OrdersReducer(state: State | undefined, action: Action) {
    return ordersReducer(state, action);
}

export const getOrdersError = (state: State) => state.error;
export const getOrdersLoading = (state: State) => state.loading;
export const getOrdersMessage = (state: State) => state.message;

export const getOrdersCurrent = (state: State) => state.order;
export const getOrdersAll = (state: State) => state.ordersAll;
//#endregion
