<div class="modal-header">
  <div class="modal-title text-center">COLORES DISPONIBLES</div>
  <!-- <span   aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-times"></i></span> -->
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="sizes font-title">
      <span class="sizes__title"></span>
      <div class="sizes__btn">
        <button *ngFor="let color of colorsAviables" mat-raised-button matBadge="1" [matBadgeHidden]="color.selected"
          (click)="onClick(color)" style="background-color:{{color.code}};">
        </button>
      </div>
    </div>
  </div>
</div>