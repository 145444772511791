import { CategoriesResponse } from '@interfaces/categories.response.interface';
import { createAction, props } from '@ngrx/store';

export enum NavActionType {
    getProductsFromDB = '[NAV] => Getting products from Database',
    getProductsFromDBSuccess = '[NAV] => Getting Products from Database Success',
    getProductsFromStore = '[NAV] => Getting Products from Store',
    getProductsFromStoreSuccess = '[NAV] => Getting Products from Store Success',
    error = '[NAV] => Error',
}
export const getProductsFromDB = createAction(NavActionType.getProductsFromDB);
export const getProductsFromDBSuccess = createAction(
    NavActionType.getProductsFromDBSuccess,
    props<{ category: CategoriesResponse }>()
);
export const getProductsFromStore = createAction(
    NavActionType.getProductsFromStore
);
export const getProductsFromStoreSuccess = createAction(
    NavActionType.getProductsFromStoreSuccess,
    props<{ category: CategoriesResponse }>()
);
export const error = createAction(NavActionType.error, props<{ error: any }>());
