<div class="category-content">
    <div class="card">
        <a (click)="goToArticulo()" routerLink="/articulo/{{ product.slug }}">
            <mat-card>
                <mat-card-content>
                    <div mat-card-avatar class="category-img-product-1">
                        <img [src]="product.image" alt>
                    </div>
                </mat-card-content>
                <mat-card-footer>
                    <div class="category">
                        <div class="category-description">
                            <span>{{ product.name }}</span>
                        </div>
                        <div class="category__container-ref-price">
                            <div class="category__ref-price font-text">
                                <span
                                    [class]="product.discount == 1 ? 'product-price tachado': 'product-price'">{{
                                    product.regular_price | moneda}}</span>
                            </div>
                            <div class="category__ref-price font-text"
                                style="height: 30px ;">
                                <span class="text-danger" *ngIf="product.discount == 1">
                                    OFERTA &nbsp;
                                </span>
                                <span class="product-price"
                                    *ngIf="product.discount == 1">
                                    {{product.sale_price |moneda}}
                                </span>
                            </div>

                        </div>
                    </div>
                </mat-card-footer>
            </mat-card>
        </a>
    </div>

</div>
