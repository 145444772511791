import { Component, OnInit } from '@angular/core';
import { Title } from 'src/app/core/interfaces/title';

@Component({
  selector: 'app-frequent-question',
  templateUrl: './frequent-question.component.html',
  styleUrls: ['./frequent-question.component.scss']
})
export class FrequentQuestionComponent implements OnInit {
  title: Title=  {
    icon: 'assets/img/user/user-menu-questions-img-icon.png',
    name: 'Preguntas Frecuentes'
  };



  constructor() { }

  ngOnInit(): void {
  }

}
