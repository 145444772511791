import { createAction, props } from '@ngrx/store';
import { OrderModel } from '@models/order.model';
import { CartOrderResponseModel } from '@models/cart-order-response.model';



export const getOrder = createAction(
    '[Orders] Get Order',
    props<{ code: string }>()
);
export const getOrderSuccess = createAction(
    '[Orders] Get Order Success',
    props<{ order: CartOrderResponseModel }>()
);

export const setOrder = createAction(
    '[Orders] Set Order',
    props<{ order: OrderModel }>()
);

export const getErrorOrders = createAction(
    '[Orders] get Error Order',
    props<{ error: string }>()
);

