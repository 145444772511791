import { ISliderPromo } from "@interfaces/slider-promo-interface";

export const sliderPromoMocks:ISliderPromo[]  = [
    {
        id:1,
        promo:"PROMOCIONES POR BLACK FRIDAY"
    },
    // {
    //     id:1,
    //     promo:"ENVÍOS GRATIS POR COMPRAS IGUALES Y MAYORES A $ 150.000"
    // },
    // {
    //     id:1,
    //     promo:"Nuevo método de pago",
    //     promo1:"Sistecrédito"
    // },

]
