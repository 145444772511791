import { Component, OnInit, Input } from '@angular/core';
import { Category } from '@interfaces/categories-interfaces';

import { Title } from 'src/app/core/interfaces/title';

@Component({
    selector: 'app-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
    @Input() title: Title;

    constructor() { }

    ngOnInit(): void {
    }

}
