import { ProductsService } from './../../core/services/products.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { productListData } from '../../test-helpers/products';
import { Title } from 'src/app/core/interfaces/title';
import { UtilsService } from '@root/utilities/utils.service';

@Component({
  selector: 'app-featured-products-company',
  templateUrl: './featured-products-company.component.html',
  styleUrls: ['./featured-products-company.component.scss']
})
export class FeaturedProductsCompanyComponent implements OnInit {
//   products = productListData;
  products = undefined;
  title: Title = {
    icon: 'assets/img/grupo-404.png',
    name: 'Empresa CMSA'
  };

  constructor(
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private utilservice: UtilsService
  ) { }

  ngOnInit(): void {
    this.utilservice.loading(true);
      this.productsService.allProdutc().subscribe( products =>{
        this.products = products;
        this.utilservice.loading(false);
      })
  }

}
