import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UserProfileService } from '@services/user-profile.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-subscribe',
    templateUrl: './subscribe.component.html',
    styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit {
    public email: FormControl;
    constructor(private userProfileService: UserProfileService) {}

    ngOnInit(): void {
        this.email = new FormControl(null, Validators.email);
    }
    onSubscriber() {
        if (this.email.status === 'INVALID') {
            Swal.fire(
                'Correo inválido',
                'Ingrese un correo electrónico válido',
                'error'
            );

            return;
        }
        this.email.disable();
        const email = {
            email: this.email.value,
            name: 'Usuario Suscribete',
            last_name: 'Usuario Suscribete',
            role_id: '2',
        };
        this.userProfileService.createSuscribete(email).subscribe(
            (resp) => {
                Swal.fire(
                    'Bienvenido',
                    'Felizmente ya estas suscrit@, pronto recibira noticias nuestras.',
                    'success'
                );
                this.email.enable();
            },
            (error) => {
                this.email.enable();
                Swal.fire(
                    'Que pena, algo falló, gracias por tu interes',
                    error.message.join(' '),
                    'error'
                );
            }
        );
    }
}
