import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Company } from 'src/app/core/interfaces/company';
import { VendorFacadeService } from '@root/features/vendor/vendor.facade.service';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
    @Input() companies: Company[];

    constructor(
        private readonly http: HttpClient,
        private vendorFacde: VendorFacadeService
    ) { }

    ngOnInit(): void {

    }

}
