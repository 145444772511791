import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { VendorModel } from '@models/vendor.model';
import { UserFacadeService } from '@facades/auth/user-facade.service';

@Injectable({
    providedIn: 'root',
})
export class FavoriteService {
    urlApi: string = environment.API_URL;
    storage: string = environment.STORAGE;
    private user;
   // token =
    //    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjA4ZDE1NGJjMTRkZTMyMmY5ZWU5MjdmZjQwNTFmZmM2YjJjMTAzZjcyYjM5MDQ3Y2I0MzYyODhhMjVlYmQ2ZDYyNmY0NjEwNjc3NTI5YzQyIn0.eyJhdWQiOiIyIiwianRpIjoiMDhkMTU0YmMxNGRlMzIyZjllZTkyN2ZmNDA1MWZmYzZiMmMxMDNmNzJiMzkwNDdjYjQzNjI4OGEyNWViZDZkNjI2ZjQ2MTA2Nzc1MjljNDIiLCJpYXQiOjE2MDk3ODQxMzAsIm5iZiI6MTYwOTc4NDEzMCwiZXhwIjoxNjExMDgwMTMwLCJzdWIiOiIxMjIiLCJzY29wZXMiOltdfQ.qcSmMQt7bRrEo11xWQnCLQVRLsbmLFJX6-mAygtHOT2eYJzHtuHM3v94jrcd2yWLFwZ89hMF15V9Nua_BmAH5ReMuyJCOGyJZYsytqEFh-L3AyLIqb_ls3jEbZvlCkivl-3bzAOjavI24fMu5kE2on9ksIMv05Nn92gWXLhX2fOrD4dn1YcCriOvRwM9nL0wLeyaQARc83DgYVKzT7FPqz9sfKTtGNCNPPhFcetpEXZnWuBPWtsY7nOem9_Djy-C-IFexWiE8DFmQkRy-DaOPZq5hpcYvmQBlDHDsBIKX_tWSFALwP-Sd4E7kSFLC7d2rmmoSP_iQpQu8U-c0JMOxldTgaXGbz91wEaNKZQ8eAAlUDC93z-uf97zO-dnMhoT3-b9hnh_mVjIIBpUhqRdQ_VQ-ct0zmmAXzXF5jZh7Du7PUbWpL3E2MK1mkxSORv5XXAI71Ro0UdlU2ukuusBR7se-SJ4wTQAJ_agtMX93JXjF_gm2sbi10jdRyEDFbp2yQflZprYUASRM82WW5jTABV3VCQyUE2_8jZkXLvESD__-Quq7Lpop22aPGFFNl_fbQydjthfpQDO955Genybul77FU8ZtAhowf4XlLV74Ik2a6uC_8LEHB2Zzr1x3gq5KwfpDrC6JBKrlKZDIiy4zx7LwocBDLPZaR-823ahUys';
    constructor(
        private http: HttpClient,
        private userfacade: UserFacadeService
    ) {
        this.userfacade.getCurrentUser$().subscribe((u) => (this.user = u));
    }
    // private httpOptions = {
    //     headers: new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         Authorization: 'Bearer ' + this.token,
    //     }),
    // };
    public addOrRemoveFavorite$(idProduct): Observable<any> {
        const options = {
            id: idProduct,
            _method: 'PATCH',
            status: true,
            user: this.user,
        };
        return this.http.post<any>(
            `${this.urlApi}products/${idProduct}/favorite`,
            options
        )
    }
    public getfavoriteFromDB$(userId): Observable<any[]> {
        let id = { id: userId };
        return this.http
            .get<any[]>(`${this.urlApi}users/${userId}/favorites`)
            .pipe(map((r: any) => r.data));
    }
}
