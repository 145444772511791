import { Injectable } from "@angular/core";
import {
    CanLoad,
    Route,
    UrlSegment,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    CanActivate,
} from "@angular/router";
import { UserFacadeService } from "@facades/auth/user-facade.service";
import { Observable, Subscription } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanLoad, CanActivate {
    subs: Subscription = new Subscription();
    user;
    constructor(private userFacade: UserFacadeService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        let sub: Subscription = this.userFacade
        .getCurrentUser$()
        .subscribe((user) => (this.user = user));
    this.subs.add(sub);
    return this.user !== null && this.user !== undefined;
    }
    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        let sub: Subscription = this.userFacade
            .getCurrentUser$()
            .subscribe((user) => (this.user = user));
        this.subs.add(sub);
        return this.user !== null && this.user !== undefined;
    }
}
