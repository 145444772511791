import { VendorModel } from "@models/vendor.model";
import { createAction, props } from "@ngrx/store";

export enum VendorActionType {
    Load = "[Vendor Component] Load",
    SearchVendors = "[Vendor Component] Search All Vendors",
    SearchVendorsSuccess = "[Vendor Component] Search All Vendor Success",
    SearchVendor = "[Vendor Component] Search Vendor",
    SearchVendorSuccess = "[Vendor Component] Search Vendor Success",
    Selected = "[Vendor Page] Select",

    UpdateVendor = "[Vendor Component] Update Vendor",
    CreateVendor = "[Vendor Component] Create Vendor",
    UpdateTotal = "[Vendor Component] Update Total",
    ActionFailure = "[Vendor API] Execute action failure",
    ActionSuccess = "[Vendor API] Execute action success",
    LoadSuccess = "[Vendor API] Load Success",
    Refresh = "[Vendor Page] Refresh",
    SubmitSuccess = "[Vendor API] Submit Success",
}

export const load = createAction(VendorActionType.Load);

export const searchVendors = createAction(
    VendorActionType.SearchVendors
);
export const searchVendorsSuccess = createAction(
    VendorActionType.SearchVendorsSuccess,
    props<{ vendorAll: VendorModel[] }>()
);

export const searchVendor = createAction(
    VendorActionType.SearchVendor,
    props<{ query: string }>()
);
export const searchVendorSuccess = createAction(
    VendorActionType.SearchVendor,
    props<{ vendor: VendorModel }>()
);

export const selectVendor = createAction(
    VendorActionType.Selected,
    props<{ vendor: VendorModel }>()
);

export const createVendor = createAction(
    VendorActionType.CreateVendor,
    props<{ vendor: VendorModel }>()
);

export const actionFailure = createAction(
    VendorActionType.ActionFailure,
    props<{ error: string }>()
);

export const actionSuccess = createAction(
    VendorActionType.ActionSuccess,
    props<{ msg: string }>()
);

export const updateTotal = createAction(
    VendorActionType.UpdateTotal,
    props<{ total: number }>()
);

export const loadSuccess = createAction(
    VendorActionType.LoadSuccess,
    props<{ vendors: VendorModel[] }>()
);

export const submitSuccess = createAction(
    VendorActionType.SubmitSuccess,
    props<{ msg: string }>()
);

export const refresh = createAction(VendorActionType.Refresh);

export const updateVendor = createAction(
    VendorActionType.UpdateVendor,
    props<{ vendor: VendorModel; ref: any }>()
);
