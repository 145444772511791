import { ICollection } from '@interfaces/collection-interface';

export const jumbotronMocks = {
    id: '1',
    image: '../../../assets/img/giovanye/desktop/jumbotron.png',
    ProductName: 'BOLSO LINEA MARLBORO',
    description:
        'La linea de bolsos Marlboro presenta su nueva colección Car formula 1 en colores de inspiración e imagen corp. by GIOVANY',
    title: 'MARLBORO',
};

export const jumbotronMocks2 = {
    id: '2',
    image: '../../../assets/img/giovanye/desktop/jumbotron_2.png',
    ProductName: 'HARLEY DAVIDSON JACKET',
    description:
        'Motorclothes. Chaquetas innovadoras también diseñadas para la seguridad de las moteras creando una tendencia única con prendas urbanas de look rebelde y con carácter. Plasma la evolución en diseño de ropa by GIOVANYE',

    title: 'MARLBORO',
};
