import { Action, createReducer, on } from "@ngrx/store";

import { AddressShippingActions } from "@root/core/store/actions";
import { DeliveryAddressModel } from "@models/delivery-address.model";



export interface State {
    address: DeliveryAddressModel;
    addresses: DeliveryAddressModel[];
    error: string | null;
    loading: boolean;
    message: string | null;
}

export const initialState: State = {
    address: null,
    addresses:[],
    error: null,
    loading: false,
    message: null,
};

const addressShippingReducer = createReducer(
    initialState,
    //#region
    //===================>[Address Shipping]
    on(AddressShippingActions.createAddressShipping, (state) => ({
        ...state,
        error: null,
        loading: true,
    })),
    on(AddressShippingActions.createAddressShippingSuccess, (state, { addressAll }) => {
        let newArr =[];
       const aux= { ...state,
        // addresses: [...state.addresses, addressAll],
        error: null,
        loading: false,
        message:"Direccion guardada",};
        newArr = [...aux.addresses, addressAll];
        aux.addresses = newArr;
        return aux;
    }),

    on(AddressShippingActions.getAddressShipping, (state) => ({
        ...state,
        error: null,
        loading: true,
    })),
    on(AddressShippingActions.setAddressShipping, (state, {address}) => ({
        ...state,
        error: null,
        loading: true,
        address
    })),
    on(AddressShippingActions.getAddressShippingSuccess, (state,{addresses}) => ({
        ...state,
        error: null,
        loading: true,
        addresses,
        message:"Direcciones de envios obtenidas"
    })),


    //#region
    //===================>[Address Shipping]
);

const updateFlags = (state: State): State => {
    return {
        ...state,
        error: null,
        loading: true,
        message: null,
    };
};

export function AddressShippingReducer(state: State | undefined, action: Action) {
    return addressShippingReducer(state, action);
}
export const getAddressShippingError = (state: State) => state.error;
export const getAddressShippingLoading = (state: State) => state.loading;
export const getAddressShippingMessage = (state: State) => state.message;
export const getCurrentAddressShipping = (state: State) => state.address;
export const getAddressesShipping = (state: State) => state.addresses;
//#endregion
