<div class="trace-guide-wrapper page-container">
    <app-title [title]="title" class="trace-guide-title"></app-title>
    <div class="trace-guide-container">
        <div class="trace-guide-alert"><span>{{ 'trace-guide.title' | translate }}</span></div>
        <div class="trace-guide-items">
            <input type="text" class="trace-guide-input" placeholder="{{ 'trace-guide.placeholder' | translate }}">
            <i class="fas fa-pen"></i>
        </div>
        <div class="btn-trace-guide">
            <button>{{ 'trace-guide.trace-guide' | translate }}</button>
        </div>
    </div>
    <app-guide-info></app-guide-info>
    <app-guide-not-located></app-guide-not-located>
</div>
