import { createAction, props } from "@ngrx/store";

export const getFavorites = createAction(
    "[Favorites] Get all favorites",
    props<{ productsId: any }>()
);
export const getFavoritesSuccess = createAction(
    "[Favorites] Get all favorites Success",
    props<{ products: any[] }>()
);
export const setFavorites = createAction(
    "[Favorites] Set as favorites",
    props<{ productsId: any }>()
);
export const setFavoritesSuccess = createAction(
    "[Favorites] Set as favorites Success",
    props<{ response: any }>()
);

export const getFavoritesFromDB = createAction(
    "[Favorites] Get all favorites from DB",
    props<{ userId: any[] }>()
);

export const getFavoritesFromDBSuccess = createAction(
    "[Favorites] Get all favorites from DB Success",
    props<{ products: any[] }>()
);
export const error = createAction(
    "[Favorites] ups something bad happened ",
    props<{ error: any }>()
);
