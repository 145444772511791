import { createAction, props } from '@ngrx/store';

export class CategoryInterface {
  slug?: string;
  name?: string;
  url?: string;
  id?: number;
  subCategories?: any;
}

export const setCategoriesInStore = createAction(
  '[Categories] set Categories In Store',
  props<{ categories: CategoryInterface[] }>()
);

export const getCategoriesFromStore = createAction(
  '[Categories] get Categories From Store',
  props<{ categories: CategoryInterface[] }>()
);

export const getCurrentCategory = createAction(
  '[Categories] get Current Category',
  props<{ category: CategoryInterface }>()
);

export const setCurrentCategory = createAction(
  '[Categories] set Current Category',
  props<{ category: CategoryInterface }>()
);
