import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAppRoot from '@reducers/index';
import { CartActions } from '@root/core/store/actions';
import { CartModel } from '@models/cart.model';
import { CorfirmPurchaseModel } from '@models/corfirmPurchase.model';
import { CartDeleteItemModel } from '@models/cart-delete-item.model';
import { CartDeleteAllItemInterface } from '@interfaces/cart-delete-all-Items-interface';
import { RequestGetCarCodeModel } from '@models/cart-request-get-cart-code-model';
import { CartUpdateItemsModel } from '@models/cart-update-items.model';
import { CartItemModel } from '@models/cart-item.model';

@Injectable({
    providedIn: 'root',
})
export class CartFacadeService {
    constructor(private store: Store<fromAppRoot.State>) {}

    searchCart(cart: CartModel) {
        this.store.dispatch(CartActions.searchCart({ cart }));
    }
    searchCartUser(userId: number) {
        this.store.dispatch(CartActions.searchCartUser({ userId }));
    }

    selectCart(cart: CartModel): void {
        this.store.dispatch(CartActions.selectCart({ cart }));
    }

    getCart$(): Observable<CartItemModel[]> {
        return this.store.select(fromAppRoot.getCart);
    }
    updateCartItmes(item: CartUpdateItemsModel) {
        this.store.dispatch(CartActions.updateCartItem({ item }));
    }

    addProduct(item: CartItemModel) {
        this.store.dispatch(CartActions.addProductCart({ item }));
    }
    getCartMessageError$(): Observable<string> {
        return this.store.select(fromAppRoot.getErrorCartMessage);
    }
    getCartMessage$(): Observable<string> {
        return this.store.select(fromAppRoot.getCartMessage);
    }
    cartReset(cart: CartModel) {
        this.store.dispatch(CartActions.carReset({ cart }));
    }
    confirmPurchase(purchase: CorfirmPurchaseModel) {
        this.store.dispatch(CartActions.confirmPurchase({ purchase }));
    }
    deleteItiemCart(item: CartDeleteItemModel) {
        this.store.dispatch(CartActions.deleteCartItem({ item }));
    }
    deleteAllItemCart(item: CartDeleteAllItemInterface) {
        this.store.dispatch(CartActions.deleteCartAllItem({ item }));
    }
    getCarCode(params: RequestGetCarCodeModel) {
        this.store.dispatch(CartActions.getCartCode({ params }));
    }
    emptyShoppingCart() {
        this.store.dispatch(CartActions.emptyShoppingcart());
    }

    //#region Cart Temporal
    removeItemTemporalCart(index: number) {
        this.store.dispatch(CartActions.deleteTemporalCartItem({ index }));
    }
    deleteAllItemTemporaCart() {
        this.store.dispatch(CartActions.deleteTemporalCartAllItem());
    }

    cleanMesssageCart(){
      this.store.dispatch(CartActions.clearCartMessage());
    }
}
