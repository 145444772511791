

export const userMenuListData = [
    {
        icon: 'assets/img/user.png',
        label: 'Perfil',
        link: "perfil"
    },
    {
        icon: 'assets/img/maker-icon.png',
        label: 'Direcciones de Entrega',
        link: "direcciones-entrega"
    },
    {
        icon: 'assets/img/user/user-menu-gestión-de-compras.png',
        label: 'Gestión de compras',
        link: "gestion-de-compras"
    },
    {
        icon: 'assets/img/user/user-menu-purchasing-management-icon.png',
        label: 'Ultimas Compras',
        link: "ultimas-compras"
    },
    {
        icon: 'assets/img/user/user-ofertas-icon.png',
        label: 'Saldos Industriales',
        link: "articulos"
    },
    {
        icon: 'assets/img/user/user-consultor-asignado.png',
        label: 'Consultor asignado',
        link: "redirectToWhatsapp"
    },
    {
        icon: 'assets/img/user/user-menu-sell-with-us-icon.png',
        label: 'Vender con nosotros',
        link: "vender-con-nosotros"
    },
    {
        icon: 'assets/img/user/user-menu-questions-img-icon.png',
        label: 'Preguntas frecuentes',
        link: "/preguntas-frecuentes"
    },
    {
        icon: 'assets/img/user/user-menu-email-orange-icon.png',
        label: 'Contactenos',
        link: "contactenos"
    },
    {
        icon: 'assets/img/user/close-session.png',
        label: 'Cerrar sesión',
        link: 'logout'
    }
];
