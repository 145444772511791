import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { AdvertisingModel } from "@models/advertising-model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class AdvertisingService {
    private urlApi: string = environment.API_URL;
    constructor(private http: HttpClient) {}

    public getAdvertisingAllActive$(): Observable<AdvertisingModel[]> {
        return this.http
            .get<AdvertisingModel[]>(`${this.urlApi}banners/active`)
            .pipe(map((resp: any) => resp.data));
    }
    public getAdvertisingAll$(): Observable<AdvertisingModel[]> {
        return this.http
            .get<AdvertisingModel[]>(`${this.urlApi}banners`)
            .pipe(map((resp: any) => resp.data));
    }
}
