import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {
  public fecha: Date = new Date();
  public anio: any =  this.fecha.getFullYear() ;
  constructor() {
    this.anio += ' Castrivilla S.A.S powered by '
   }

  ngOnInit(): void {
  }

}
