import { Component, OnInit } from '@angular/core';
import { AddressFacadeService } from '../../../features/delivery-address/delivery-address-facade.service';
import { UtilitiesApisService } from '../../../core/services/utilities.apis.service';
import { MunicipiosInterface } from '../../../core/interfaces/municipios.interface';
import { Subscription } from 'rxjs';
import { UserFacadeService } from '../../../core/facades/auth/user-facade.service';
import { MessageService } from '../../../core/services/message.service';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    Validators,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-address-modal',
    templateUrl: './address-modal.component.html',
    styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit {
    public form: UntypedFormGroup;
    public departamentos = [];
    public municipios = [];
    public allData = [];
    public subs: Subscription = new Subscription();
    public user;

    constructor(
        private fb: UntypedFormBuilder,
        private addressFacade: AddressFacadeService,
        private utilitiesApisService: UtilitiesApisService,
        private userFacadeService: UserFacadeService,
        private messageService: MessageService,
        public dialog: MatDialog
    ) {
        this.buildForm();
    }

    ngOnInit(): void {
        this.getDepartamentos();
        this.subscription();
    }
    public subscription() {
        const sub: Subscription = this.userFacadeService
            .getCurrentUser$()
            .subscribe((user) => {
                this.user = user;
            });
        this.subs.add(sub);

        const sub2: Subscription = this.addressFacade
            .getMessage$()
            .subscribe((msg) => {
                if (msg === 'Direccion guardada') {
                    this.messageService.showCustom(msg, null, 'success');
                    this.dialog.closeAll();
                }
            });
    }
    public buildForm(): void {
        this.form = this.fb.group({
            user_id: new UntypedFormControl(null),
            name: new UntypedFormControl(null, Validators.required),
            address: new UntypedFormControl(null, Validators.required),
            country: new UntypedFormControl('Colombia', Validators.required),
            city: new UntypedFormControl(null, Validators.required),
            state: new UntypedFormControl(null, Validators.required),
            municipio: new UntypedFormControl(null, Validators.required),
            zip_code: new UntypedFormControl(null),
        });
    }

    public getDepartamentos(): void {
        this.utilitiesApisService.getDepartamentos$().subscribe((items) => {
            this.departamentos = this.buildData(items);
            this.allData = items;
        });
    }

    public buildData(listMun: MunicipiosInterface[]): string[] {
        const departamentos = [];
        listMun.forEach((i) => {
            if (!departamentos.find((searc) => i.departamento === searc)) {
                departamentos.push(i.departamento);
            }
        });
        return departamentos;
    }

    public filterMun() {
        this.municipios = [];
        const departamento = this.form.get('state').value;
        this.allData.forEach((i: MunicipiosInterface) => {
            if (i.departamento === departamento) {
                this.municipios.push(i.municipio);
            }
        });

        this.municipios.sort();
    }

    public add() {
        const data = this.form.value;
        data.user_id = this.user.id;
        data.address = data.address + ', ' + data.city + ', ' + data.municipio;
        this.addressFacade.createAddressShipping(data);
    }

    public setPostalCode() {}
}
