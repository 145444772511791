import { createAction, props } from '@ngrx/store';

import { CartCodeModel } from '@models/cart-code.model';
import { CartModel } from '@models/cart.model';
import { CartItemModel } from '@models/cart-item.model';
import { AddProductCartModel } from '@models/addProductCart.model';
import { AddProductCarResponse } from '@models/addProductCartResponse.model';
import { CartContentModel } from '@models/cart-content.model';
import { CorfirmPurchaseModel } from '@models/corfirmPurchase.model';
import { OrderModel } from '@models/order.model';
import { CartOrderResponseModel } from '@models/cart-order-response.model';
import { CartDeleteItemModel } from '@models/cart-delete-item.model';
import { CartDeleteAllItemInterface } from '@interfaces/cart-delete-all-Items-interface';
import { RequestGetCarCodeModel } from '@models/cart-request-get-cart-code-model';
import { CartUpdateItemsModel } from '@models/cart-update-items.model';

export const addProductCart = createAction(
    '[Cart] add Produc Cart',
    props<{ item: CartItemModel }>()
);

export const addProductCartSuccess = createAction(
    '[Cart] add Produc Cart Success',
    props<{ cart: CartContentModel }>()
);

export const createCart = createAction(
    '[Cart] Create Cart',
    props<{ cart: CartCodeModel }>()
);
export const createCartSuccess = createAction(
    '[Cart] Create Cart CarSuccess',
    props<{ cart: CartModel }>()
);

export const searchCart = createAction(
    '[Cart] Search Cart',
    props<{ cart: CartModel }>()
);
export const searchCartSuccess = createAction(
    '[Cart] Search Car Success',
    props<{ cartItems: CartContentModel }>()
);

export const searchCartUser = createAction(
    '[Cart] Search Cart User Cart',
    props<{ userId: number }>()
);
export const searchCartUserSuccess = createAction(
    '[Cart] Search Cart User Cart Success',
    props<{ carts: CartModel[] }>()
);

export const deleteCart = createAction('[Cart] Delete Cart');
export const deleteCartSuccess = createAction(
    '[Cart] Delete Cart success',
    props<{ cart: CartModel }>()
);

export const updateCart = createAction('[Cart] Update Cart');
export const updateCartSuccess = createAction(
    '[Cart] Login office restore success',
    props<{ cart: CartModel }>()
);

export const updateCartItem = createAction(
    '[Cart] Update Cart Item',
    props<{ item: CartUpdateItemsModel }>()
);
export const updateCartItemSuccess = createAction(
    '[Cart] Update Cart Item success',
    props<{ cart: CartModel }>()
);

export const cartError = createAction(
    '[Cart] Cart Errors',
    props<{ error: string | null }>()
);
export const selectCart = createAction(
    '[Cart] Select Cart',
    props<{ cart: CartModel }>()
);
export const selectCartSuccess = createAction(
    '[Cart] Select Cart Success',
    props<{ cart: CartModel }>()
);
export const confirmPurchase = createAction(
    '[Cart] confirm Purchase',
    props<{ purchase: CorfirmPurchaseModel }>()
);

export const confirmPurchaseSuccess = createAction(
    '[Cart] confirm Purchase Success',
    props<{ order: CartOrderResponseModel }>()
);
export const carReset = createAction(
    '[Cart] confirm Purchase',
    props<{ cart: CartModel }>()
);

export const deleteCartItem = createAction(
    '[Cart] Delete Cart Item',
    props<{ item: CartDeleteItemModel }>()
);
export const deleteCartItemSuccess = createAction(
    '[Cart] Delete Cart Item Success',
    props<{ item: CartDeleteItemModel; resp?: any }>()
);

export const deleteCartAllItem = createAction(
    '[Cart] Delete Cart All Item',
    props<{ item: CartDeleteAllItemInterface }>()
);
export const deleteCartAllItemSuccess = createAction(
    '[Cart] Delete Cart All Item Success',
    props<{ msg: string }>()
);

export const getCartCode = createAction(
    '[Cart] Get Car code',
    props<{ params: RequestGetCarCodeModel }>()
);
export const getCartCodeSuccess = createAction(
    '[Cart] Get Car Code Success',
    props<{ cartAll: CartModel[] }>()
);

export const emptyShoppingcart = createAction('[Cart] Empty Shopping Cart');
export const setCartItems = createAction(
    '[Cart] Set Cart Items',
    props<{ cartItems: CartContentModel }>()
);

///////////// CART TEMP ////////////////////////
export const deleteTemporalCartItem = createAction(
    '[Cart] Delete Temporal Cart Item',
    props<{ index: number }>()
);
export const deleteTemporalCartItemSuccess = createAction(
    '[Cart] Delete Temporal Cart Item Success',
    props<{ item: CartDeleteItemModel; resp?: any }>()
);

export const deleteTemporalCartAllItem = createAction(
    '[Cart] Delete Temporal Cart All Item'
);
export const deleteTemporalCartAllItemSuccess = createAction(
    '[Cart] Delete Temporal Cart All Item Success',
    props<{ msg: string }>()
);

export const clearCartMessage = createAction(
  '[Cart] Clear message cart',
);
