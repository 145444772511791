import { getFavorites } from './favorites-.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAppRoot from '@reducers/index';
import { UserModel } from '@models/user.model';
import { favoritesActions } from '@root/core/store/actions';

@Injectable({
    providedIn: 'root',
})
export class FavoriteFacadeService {
    constructor(private store: Store<fromAppRoot.State>) {}

    getFavorite$(): Observable<any[]> {
        return this.store.select(fromAppRoot.getFavorites);
    }
    setFavorite(productsId) {
        this.store.dispatch(favoritesActions.setFavorites({ productsId }));
    }

    getFavoriteFromDB(userId) {
        this.store.dispatch(favoritesActions.getFavoritesFromDB({ userId }));
    }
    getFavoriteMessage$(): Observable<any> {
        return this.store.select(fromAppRoot.getFavoritesMessage);
    }
}
