import { CategoriesResponse } from '@interfaces/categories.response.interface';
import { Action, createReducer, on } from '@ngrx/store';
import { navActions } from '@root/core/store/actions';

export interface State {
    categories: CategoriesResponse;
    loading: boolean;
    message: string;
    error: any;
}

export const initialState: State = {
    categories: null,
    loading: false,
    message: null,
    error: null,
};

const NavReducer = createReducer(
    initialState,
    on(navActions.getProductsFromDB, (state) => ({
        ...state,
    })),
    on(navActions.getProductsFromDBSuccess, (state, { category }) => ({
        ...state,
        categories: category,
    }))
);

export function reducer(state: State, action: Action) {
    return NavReducer(state, action);
}
export const getNavCategories = (state: State) => state.categories;
export const getNavLoading = (state: State) => state.loading;
export const getNavMessage = (state: State) => state.message;
export const getNavError = (state: State) => state.error;
