import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { jumbotronMocks } from '@root/core/mocks/jumbotron-mocks';
import { typeListData } from '@root/test-helpers/types-list';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-hoarding',
  templateUrl: './hoarding.component.html',
  styleUrls: ['./hoarding.component.scss'],
})
export class HoardingComponent implements OnInit {
  @Input() filter: string;
  @Input() title: string;
  @Input() public data: any;
  public typeList = typeListData;
  public urlbase = environment.STORAGE;
  public url: any;
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
