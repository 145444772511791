import { FavoriteFacadeService } from './../../../../../shared/components/favorites/store/favorites-facade.service';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { AuthService } from '@services/auth';
import { AuthActions } from '@root/core/store/actions';
import { UserModel } from '@models/user.model';
import Swal from 'sweetalert2';
import { CartFacadeService } from '@root/pages/cart/cart-facade.service';
import { UserProfileService } from '@services/user-profile.service';

@Injectable()
export class AuthEffects {
    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.login),
            switchMap(({ credential }) =>
                this.authService.login$(credential).pipe(
                    map((user: UserModel) =>
                        AuthActions.loginSuccess({ user })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthActions.loginFailure({ error: error.message }))
                    )
                )
            )
        )
    );
    loginWithgoogle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loginWithGoogle),
            switchMap(({ credential }) =>
                this.authService.loginWithGoogle$(credential).pipe(
                    map((user: UserModel) =>
                        AuthActions.loginSuccess({ user })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthActions.loginFailure({ error: error.message }))
                    )
                )
            )
        )
    );
    loginWithfacebook$ = createEffect(() =>
    this.actions$.pipe(
        ofType(AuthActions.loginWithFaceBook),
        switchMap(({ credential }) =>
            this.authService.loginWithFacebook(credential).pipe(
                map((user: UserModel) =>
                    AuthActions.loginSuccess({ user })
                ),
                catchError((error: HttpErrorResponse) =>
                    of(AuthActions.loginFailure({ error: error.message }))
                )
            )
        )
    )
);
    loginSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loginSuccess),
                tap((e) => {
                    this.cartFacadeService.searchCartUser(e.user.id);
                }),
                tap((e: any) => {
                    if (e.user.name) {
                        this.favoriteFacade.getFavoriteFromDB(e.user.id);
                        Swal.fire({
                            title: 'INICIO DE SESIÓN',
                            icon: 'success',
                            text: `Bienvenido ${e.user.name} ${e.user.last_name}`,
                        });
                        this.router.navigateByUrl('/carrito-de-compras');
                    } else {
                        Swal.fire({
                            title: 'INICIO DE SESIÓN',
                            icon: 'error',
                            text: e.user.message,
                        });
                    }
                })
            ),
        { dispatch: false }
    );

    createUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.createUser),
            switchMap(({ user }) =>
                this.authService.createUser$(user).pipe(
                    map((resp: any) => {
                        return AuthActions.createUserSuccess({ resp });
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthActions.loginFailure({ error: error.message }))
                    )
                )
            )
        )
    );
    createUserSucces$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.createUserSuccess),
                tap((_) => {
                    Swal.fire({
                        title: 'CUENTA CREADA',
                        icon: 'success',
                        text: `Su cuenta fue creada satisfactoriamente. Ya puede iniciar ingresar a su cuenta.`,
                    });
                    this.router.navigateByUrl('/login');
                })
            ),
        { dispatch: false }
    );

    setUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.setUser),
                tap((e) => {
                    this.cartFacadeService.searchCartUser(e.user.id);
                })
            ),
        { dispatch: false }
    );
    updateUserProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.updateUserProfile),
            switchMap(({ user }) =>
                this.userProfileService.updateUserProfile$(user).pipe(
                    map((user: UserModel) =>
                        AuthActions.updateUserProfileSuccess({ user })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            AuthActions.error({
                                error: error.message,
                            })
                        )
                    )
                )
            )
        )
    );
    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logout),
            switchMap(() =>
                this.authService.logout$().pipe(
                    map(() => AuthActions.logoutSuccess()),
                    tap((_) => {
                        Swal.fire({
                            title: 'CIERRE DE SESIÓN',
                            icon: 'success',
                            text: 'Ha sido un placer servirle, regrese pronto',
                        });
                        this.router.navigate(['home']);
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(AuthActions.loginFailure({ error: error.message }))
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private cartFacadeService: CartFacadeService,
        private userProfileService: UserProfileService,
        private favoriteFacade: FavoriteFacadeService
    ) {}
}
