<mat-dialog-actions>
	<button mat-button mat-dialog-close>X</button>
	&nbsp;&nbsp;&nbsp;
</mat-dialog-actions>
<mat-dialog-content class="mat-typography add-address">
	<div class="profile container">
		<h4>Agregar dirección</h4>
		<h4  *ngIf="!user" class="alert">Debe estar logueado para poder registrar una direccion</h4>
		<form [formGroup]="form" action="">
			<fieldset class="row">
				<div class="profile__form col-xl-6">
					<label for="">Departamento<span class="text-danger">*</span></label>
					<select formControlName="state"  required (change)="filterMun()" >
						<option value="Selecciona un departamento">Selecciona un departamento</option>
						<option value="{{departamento}}" *ngFor="let departamento of departamentos">{{departamento}}</option>
					</select>
				</div>
				<div class="profile__form col-xl-6">
					<label for="">Municipio<span class="text-danger">*</span></label>
					<select formControlName='municipio' (change)="setPostalCode()" required >
						<option value="Selecciona una ciudad">Selecciona un Municipio</option>
						<option value="{{municipio}}" *ngFor="let municipio of municipios">{{municipio}}</option>

					</select>
				</div>
				<div class="profile__form col-xl-6">
					<label for="">Ciudad<span class="text-danger">*</span></label>
					<input formControlName="city" class="shadow-sm" required placeholder="Ingresa tu ciudad" type="text">
				</div>
				<div class="profile__form col-xl-6">
					<label for="">Barrio<span class="text-danger">*</span></label>
					<input class="shadow-sm" required placeholder="Ingresa tu barrio" type="text">
				</div>
				<div class="profile__form col-xl-6">
					<label for="">Direccion<span class="text-danger">*</span></label>
					<input formControlName="address" class="shadow-sm" required placeholder='example Calle 20 #36-04, Apto, 401' type="text">
				</div>
				<div class="profile__form col-xl-6">
					<label for="">Nombre la dirección<span class="text-danger">*</span></label>
					<input  type="text" class="shadow-sm" formControlName="name" required placeholder="Example: Mi casa, Mi trabajo etc" >
				</div>

				<div class="btn-address">
					<button [disabled]="form.invalid" type="button"  (click)="add()" >Agregar</button>
				</div>
				&nbsp;&nbsp;&nbsp;&nbsp;
			</fieldset>
		</form>
	</div>

</mat-dialog-content>
