import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

import { FooterMenuList } from './footer-list.interface';
import { footerMenuListData } from './../../../test-helpers/footer-list';
import { UtilsService } from '../../../utilities/utils.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {
  footerList: FooterMenuList[] = footerMenuListData;
  @Input() show: boolean = true;
  constructor(private utilService: UtilsService

  ) { }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.utilService.getFooterState().subscribe(state => this.show = state)
  }


}
