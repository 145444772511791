import { ProductModel } from "@models/product.model";
import { createAction, props } from "@ngrx/store";

export enum ProductActionType {
    Load                        = "[Product Component] Load",
    SearchProduct               = "[Product Component] Search Product",
    SearchProductSuccess        = "[Product Component] Search Product Success",
    SearchAllProducts           = "[Product Component] Search All Product",
    SearchAllProductsSuccess    = "[Product Component] Search All Product Success",
    SearchQueryProducts         = "[Product Component] Search query Product",
    SearchQueryProductsSuccess  = "[Product Component] Search query Product Success",
    Selected                    = "[Product Component] Select",
    Error                       = "[Product Component] Error",
    searchOffersProducts        = "[Product Component] Search Offers Products",
    searchOffersProductsSuccess = "[Product Component] Search Offers Products Success",

}

export const load = createAction(ProductActionType.Load);

export const searchProduct = createAction(
    ProductActionType.SearchProduct,
    props<{ id: number }>()
);
export const searchProductSuccess = createAction(
    ProductActionType.SearchProductSuccess,
    props<{ product : ProductModel }>()
);

export const searchAllProducts = createAction(
    ProductActionType.SearchAllProducts,
);

export const searchAllProductsSuccess = createAction(
    ProductActionType.SearchAllProductsSuccess,
    props<{ productsAll: ProductModel[] }>()
);

export const searchQueryProducts = createAction(
    ProductActionType.SearchQueryProducts,
    props<{ query: string }>()
);

export const searchQueryProductsSuccess = createAction(
    ProductActionType.SearchQueryProductsSuccess,
    props<{ productsAll: ProductModel[] }>()
);

export const selectProduct = createAction(
    ProductActionType.Selected,
    props<{ product: ProductModel }>()
);

export const productError = createAction(
    ProductActionType.Error,
    props<{ error: string | null }>()
);

export const searchOffersProducts = createAction(
    ProductActionType.searchOffersProducts,
);

export const searchOffersProductsSuccess = createAction(
    ProductActionType.searchOffersProductsSuccess,
    props<{ productsAll: ProductModel[] }>()
);
