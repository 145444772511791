import { AuthEffects } from "@root/auth/components/login/store/effects/auth.effects";
import { AddressEffects } from "@root/features/delivery-address/store/delivery-address.effects";
import { OrdersEffects } from "@root/features/orders/store/orders-effects";
import { UserProfileEffects } from "@root/features/user-profile/store/user-profile.effects";
import { VendorStoreEffects } from "@root/features/vendor/vendor-store/vendor-effects";
import { CartEffects } from "@root/pages/cart/store/cart.effects";
import { AdvertisingEffects } from "@root/shared/components/advertising/store/advertising-effects";
import { FavoritesEffects } from "@root/shared/components/favorites/store/favorites.effects";
import { NavEffects } from "@root/shared/components/nav/store/nav.effects";
import { ProductStoreEffects } from "@root/shared/components/product/store/product-effects";

export const effects = [
    AuthEffects,
    CartEffects,
    AddressEffects,
    OrdersEffects,
    VendorStoreEffects,
    ProductStoreEffects,
    UserProfileEffects,
    AdvertisingEffects,
    FavoritesEffects,
    NavEffects,
];
