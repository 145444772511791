<div class="container subscribe-wrapper">
    <div class="row suscribe-container">
        <div class="col-12 col-md-6 subscribe-content">
            <div class="title">
                <h2 class="font-title">Entra en el mundo giovanye</h2>
                <span class="font-text">Recibe información sobre eventos , colecciones y noticias exclusivas.</span>
            </div>
            <div class="footer-suscribe">
                <div class="suscribe">
                    <h3 class="font-text">Dirección de correo electrónico*</h3>
                </div>
                <form action="">
                    <input #inputValue (keyup.enter)="onSubscriber()" [formControl]="email" class=" email font-text"
                        type="email" placeholder="Introducir la Dirección de correo electrónico">
                    <button type="button" class="btn-suscribe" (click)="onSubscriber()"> &#8594; </button>
                </form>
            </div>
        </div>
        <div class="col-md-1 border-line"></div>
        <div class="col-12 col-md-5">
            <div class="register-section">
                <a routerLink="/registro">
                    <img class="img-fluid img-logo" src="assets/img/giovanye/desktop/register-logo.png" alt="">
                </a>
                <div class="register-section-text font-text">
                    <p class="font-text">
                        <img class="img-fluid img-logo-sm" src="assets/img/giovanye/desktop/logo-sm.png" alt="">
                        Compra GIOVANYE y haz parte de esta comunidad
                        <br>
                        gana descuentos y muchos beneficios que tenenmos para tí.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
