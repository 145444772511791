import { Injectable, Inject } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { Router } from "@angular/router";
import { UserProfilerActions } from "@root/core/store/actions";
import { OrderService } from "@services/orders.service";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";
import { CartOrderResponseModel } from "@models/cart-order-response.model";
import { UserProfileService } from "@services/user-profile.service";
import { UserModel } from "@models/user.model";

@Injectable()
export class UserProfileEffects {
    constructor(
        private actions$: Actions,
        private userProfileService: UserProfileService
    ) {}
    updateUserProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserProfilerActions.updateUserProfile),
            switchMap(({ user }) =>
                this.userProfileService.updateUserProfile$(user).pipe(
                    map((user: UserModel) =>
                        UserProfilerActions.updateUserProfileSuccess({ user })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            UserProfilerActions.error({
                                error: error.message,
                            })
                        )
                    )
                )
            )
        )
    );
}
