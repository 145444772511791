<div class="pad-top"></div>
<div class="delivery-address-wrapper page-container">
    <app-title [title]="title"></app-title>
    <div class="delivery-address-content">
        <div class="delivery-address-items">
            <div class="delivery-address-title">
                <i class="fas fa-map-marker-alt"></i>
                <span>Agregar una dirección de entrega</span>
            </div>
            <div class="delivery-address">
                <div class="delivery-address-items">
                    <ul>
                        <li>
                            <span class="delivery-address-icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </span>
                            <a

                            >
                                <span id='editAddress' (click)="goToEditAddress()">Tu ubicación actual</span>
                                <i class="fas fa-chevron-right icon-option"></i>
                            </a>
                        </li>
                        <li *ngFor="let address of AddressesAll">
                            <span class="delivery-address-icon-selec">
                                <i class="fas fa-map-marker-alt icon-selec"></i>
                            </span>
                            <span class="favorite">
                                <i  class="far fa-star icon-selec"></i>
                            </span>
                            <span class="delivery-address"
                                >{{ address.address }}
                                <small>{{ address.name | uppercase}}</small>
                            </span>
                            <i class="fas fa-ellipsis-v icon-menu"></i>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

