<div class="carousel-wrapper main-carousel-wrapper">
  <div class="slider-wrapper">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let slider of advertisingAll">
        <div class="slider-wrapper">
          <div class="img-banner">
            <ng-template ngIf="  ; then video; else image"></ng-template>

            <ng-container *ngIf="slider.isVideo; then video; else image">
            </ng-container>

            <ng-template #video class="video">
              <video [src]="slider.image" preload [muted]="mute" autoplay  loop type="video/webm">
                <p>Your browser cannot play the provided video file.</p>

              </video>
              <mat-icon (click)="muted()" >{{ icon }}</mat-icon>
            </ng-template>
            <ng-template #image>
              <img [src]="slider.image" alt=" video" />
            </ng-template>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>

  <div class="slider-text-container">
    <span class="font-title">
     {{titleLine1}}
      <span class="font-title"> {{titleLine2}}</span>
    </span>
    <div class="btn-category">
      <button
        class="bdtn"
        routerLink="/lista-articulos/MEN"
        routerLinkActive="active"
      >
        {{textButtonLeft}}
      </button>
      <button
        class="btdn"
        routerLink="/lista-articulos/WOMEN"
        routerLinkActive="active"
      >
        {{textButtonRith}}
      </button>
    </div>
  </div>
</div>
