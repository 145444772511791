import { of } from "rxjs";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, tap, } from "rxjs/operators";
import { productsActions } from "@root/core/store/actions";
import { VendorFacadeService } from "@root/features/vendor/vendor.facade.service";
import { ProductsService } from "@services/products.service";
import { ProductModel } from "@models/product.model";

@Injectable()
export class ProductStoreEffects {
  constructor(
    private actions$: Actions,
    private VendorFacade: VendorFacadeService,
    private productService: ProductsService
  ) { }

  selectProductEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.selectProduct),
      tap(({ product }) =>
        this.VendorFacade.selectVendor(product.vendor_id)
      ),
      catchError((error: any) =>
        of(productsActions.productError({ error }))
      )
    )
  );

  searchQueryProductEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.searchQueryProducts),
      switchMap(({ query }) =>
        this.productService.searchProduct(query).pipe(
          map((productsAll: ProductModel[]) =>
            productsActions.searchQueryProductsSuccess({
              productsAll,
            })
          ),
          catchError((error: any) =>
            of(productsActions.productError({ error }))
          )
        )
      )
    )
  );
  searchOffersProductsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.searchOffersProducts),
      switchMap((e) =>
        this.productService.offersProdutc().pipe(
          map((productsAll: ProductModel[]) =>
            productsActions.searchOffersProductsSuccess({
              productsAll,
            })
          ),
          catchError((error: any) =>
            of(productsActions.productError({ error }))
          )
        )
      )
    )
  );
}
