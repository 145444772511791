import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { VendorModel } from '@models/vendor.model';
import { MunicipiosInterface } from '@interfaces/municipios.interface';
import { IipResponse } from '@interfaces/api.ip.response.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { ResponseNav } from '@interfaces/response-nav.interface';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesApisService {
  public inPesos: boolean = true;
  public urlApi: string = environment.API_URL;
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  getIsInPesos(): boolean {
    return this.inPesos;
  }

  public getDepartamentos$(): Observable<MunicipiosInterface[]> {
    return this.http.get<MunicipiosInterface[]>(
      `https://www.datos.gov.co/resource/xdk5-pm3f.json`
    );
  }
  public getCategoriesTree$(): Observable<any> {
    return this.http.get<MunicipiosInterface[]>(
      `https://www.datos.gov.co/resource/xdk5-pm3f.json`
    );
  }
  public getCountryByIp$(): Observable<any> {
    return this.http.get<any>(`https://ipinfo.io?token=b4c1f8355b45d0`).pipe(
      map((a: IipResponse) => {
        this.inPesos = a.country === 'CO';
        return this.inPesos;
      })
    );
  }
  public getConfig$(): Observable<any> {
    const url = `${this.urlApi}settings/home`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        let filter = [];
        data.forEach((element) => {
          if (element.component.includes('HOARDING')) {
            if (element && element.data && element.data.image) {
              element.data.image = this.sanitizer.bypassSecurityTrustResourceUrl(
                environment.STORAGE + element.data.image
              );
              filter.push(element)
            }
          } else {
            filter.push(element)
          }
        });

        return filter;
      })
    );
  }
  public getNavProductsCategories$(): Observable<any> {
    const url = `${this.urlApi}categories/nav`;
    return this.http.get<ResponseNav>(url)
      .pipe(
        map(resp => {
          let newFormat: ResponseNav = {
            ...resp,
            product: []
          };
          resp.product.forEach((item) => {
            let image = [];
            item.image_main =  item.image_main  ? environment.STORAGE + item.image_main: null
            item.image?.forEach(img => {
              image.push(environment.STORAGE + img)
            })

            newFormat.product.push({
              ...item,
              image,
            });
          });
          return newFormat;
        })
      );
  }
}
