import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserModel } from '@models/user.model';

import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { AuthenticateModel } from '@models/security/authenticate.model';
import { UtilsService } from '@root/utilities/utils.service';
import { SocialUser } from '@alenosmanovic91x/angularx-social-login';


@Injectable({
    providedIn: 'root',
})
export class AuthService {
    access_token: string = '';
    token = '';
    urlApi: string = environment.API_URL;
    Headers = new HttpHeaders().set('Content-Type', 'application/json');
    constructor(private http: HttpClient, private utilService: UtilsService,) {}


    public setHttpOption() {
    }
    loginCallback(token: string, error: any) {
        if (error) {
            return;
        }
        this.access_token = token;
    }

    createUser$(user: UserModel): Observable<UserModel> {
        let formdata = new FormData();
        formdata.append('_method', 'PUT');
        formdata.append('name', user.name);
        formdata.append('birthday', '' + user.birthday);
        formdata.append('is_juristic', user.is_juristic == true ? '1' : '0');
        formdata.append('cc', user.cc ? user.cc : '0');
        formdata.append('company', user.company);
        formdata.append('email', user.email);
        formdata.append('gender', user.gender);
        formdata.append('job', user.job);
        formdata.append('last_name', user.last_name);
        formdata.append('nit', user.nit ? user.nit : '0');
        formdata.append('phone', user.phone);
        formdata.append('password', user.password);
        formdata.append('password_confirmation', user.password_confirmation);
        formdata.append('role_id', '2');

        if (
            user.chamber_commerce &&
            (user.chamber_commerce.type === 'image/jpg' ||
                user.chamber_commerce.type === 'image/png' ||
                user.chamber_commerce.type === 'image/jpeg')
        ) {
            formdata.append(
                'chamber_commerce',
                user.chamber_commerce,
                user.chamber_commerce.name
            );
        }
        if (
            user.representative_id &&
            (user.representative_id.type === 'image/jpg' ||
                user.representative_id.type === 'image/png' ||
                user.representative_id.type === 'image/jpeg')
        ) {
            formdata.append(
                'representative_id',
                user.representative_id,
                user.representative_id.name
            );
        }
        if (
            user.rut &&
            (user.rut.type === 'image/jpg' ||
                user.rut.type === 'image/png' ||
                user.rut.type === 'image/jpeg')
        ) {
            formdata.append('rut', user.rut, user.rut.name);
        }
        return this.http.post<UserModel>(this.urlApi + `users`, formdata).pipe(
            tap((resp) => {
                return resp;
            })
        );
    }

    login$(credential: AuthenticateModel): Observable<UserModel> {
        return this.http
            .post<UserModel>(this.urlApi + `login`, credential)
            .pipe(
                tap(
                    (resp) => {
                        localStorage.setItem('user', JSON.stringify(resp));
                        return resp;
                    },
                    (error) => {
                        return error;
                    }
                )
            );
    }
    loginWithGoogle$(credential: SocialUser): Observable<UserModel> {
        return this.http
            .post<UserModel>(this.urlApi + `login/google`, credential)
            .pipe(
                tap(
                    (resp) => {
                        localStorage.setItem('user', JSON.stringify(resp));
                        return resp;
                    },
                    (error) => {
                        return error;
                    }
                )
            );
    }
    loginWithFacebook(credential: SocialUser): Observable<UserModel> {
        return this.http
            .post<UserModel>(this.urlApi + `login/google`, credential)
            .pipe(
                tap(
                    (resp) => {
                        localStorage.setItem('user', JSON.stringify(resp));
                        return resp;
                    },
                    (error) => {
                        return error;
                    }
                )
            );
    }

    currentToken(type: 'silent' | 'popup' = 'silent'): Observable<string> {
        switch (type) {
            case 'silent':
                return of('currentToken por hacer');
            case 'popup':
                return of('currentToken por hacer');
            default:
                break;
        }
    }

    logout$(): Observable<boolean> {
        localStorage.clear();
        return of(true);
    }

    resetPass$(email: any) {
        let url = 'users/password/request-token';
        return this.http
            .post<any>(this.urlApi + url, email)
            .pipe(
                tap((e) =>
                    this.utilService.consoleLog(
                        '%cauth.service.ts line:93 e',
                        'color: white; background-color: #007acc;',
                        e
                    )
                )
            );
    }
    saveNewPass$(data: any) {
        let url = 'users/password/set-new-password';
        return this.http
            .post<any>(this.urlApi + url, data)
            .pipe(
                tap((e) =>
                    this.utilService.consoleLog(
                        '%cauth.service.ts line:93 e',
                        'color: white; background-color: #007acc;',
                        e
                    )
                )
            );
    }
}
