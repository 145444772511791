
import { VendorModel } from '@models/vendor.model';
import { Action, createReducer, on } from '@ngrx/store';
import { vendorActions } from '@root/core/store/actions';

export interface State {
    vendor: VendorModel;
    vendorAll: VendorModel[];
    error: string | null;
    loading: boolean;
    message: string | null;
}

export const initialState: State = {
    vendor: null,
    vendorAll:[],
    error: null,
    loading: true,
    message: null
};

const vendorReducer = createReducer(
  initialState,
  on(vendorActions.load, state => ({
    ...state,
    loading: true,
    error: null,
    message: null
  })),
  on(vendorActions.searchVendors, (state) => ({
    ...updateFlags(state),
  })),
// on(vendorActions.searchVendors, (state) => ({ ...state, error: null, loading: true })),
  on(vendorActions.searchVendorsSuccess, (state, {vendorAll}) => ({
    ...state,
    loading: false,
    error: null,
    message: null,
    vendorAll
  })),
  on(vendorActions.searchVendor, (state) => ({
    ...state,
    loading: true,
    error: null,
    message: null
  })),
  on(vendorActions.searchVendorSuccess, (state, {vendor}) => ({
    ...state,
    loading: false,
    error: null,
    message: null,
    vendor
  })),
  on(vendorActions.updateTotal, (state, { total }) => {
    return {...state, total};
  }),
  on(vendorActions.actionFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  on(vendorActions.selectVendor, (state, { vendor }) => ({
      ...state,
      vendor,
      loading: false,
      error: null,
      message: null,
  })),

);

const updateFlags = (state: State): State => {
    return {
        ...state,
        error: null,
        loading: true,
        message: null,
    };
};

export function VendorReducer(state: State | undefined, action: Action) {
  return vendorReducer(state, action);
}

export const getVendorError = (state: State) => state.error;
export const getVendorLoading = (state: State) => state.loading;
export const getVendorMessage = (state: State) => state.message;

export const getVendorCurrent = (state: State) => state.vendor;
export const getVendorAll= (state: State) => state.vendorAll;
