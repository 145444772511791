import { createAction, props } from "@ngrx/store";
import { UserModel } from "@models/user.model";

export const createUserProfile = createAction(
    "[User] Create User",
    props<{ user: UserModel }>()
);
export const createUserProfileSuccess = createAction(
    "[User] Create User Success",
    props<{ user: UserModel }>()
);

export const updateUserProfile = createAction(
    "[User] Update User",
    props<{ user: UserModel }>()
);
export const updateUserProfileSuccess = createAction(
    "[User] Update User Success",
    props<{ user: UserModel }>()
);
export const error = createAction(
    "[User] User Error",
    props<{ error: any }>()
);
